import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AlertModule } from 'ngx-bootstrap/alert';
import { mockInterceptorProvider } from 'src/shared/interceptors/mock.interceptor';

import { environment } from '../environments/environment';
import { AuthGuard } from './../shared/guards/auth.guard';
import { ErrorInterceptor } from './../shared/interceptors/error.interceptor';
import { HeaderInterceptor } from './../shared/interceptors/header.interceptor';
import { LoaderInterceptor } from './../shared/interceptors/loader.interceptor';
import { SharedModule } from './../shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { componentsApp } from './components';
import { containersApp } from './containers';

@NgModule({
    declarations: [
        AppComponent,
        ...componentsApp,
        ...containersApp,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxsModule.forRoot([], { developmentMode: !environment.production, selectorOptions: { suppressErrors: false } }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        BrowserAnimationsModule,
        AlertModule.forRoot(),
        SharedModule
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        environment.mock.enable ? mockInterceptorProvider : []
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }

