import { IAnalyse, IAnalyseOut } from 'src/views/sites/interfaces/pss.interface';
import { IRiskDtoOut } from 'src/views/sites/interfaces/risk.interface';

import { Site } from './../../../views/sites/models/site.model';

export class CreateSite {
    static readonly type = '[Site] Create';
    constructor(public payload: { site: Site }) { }
}

export class EditSite {
    static readonly type = '[Site] Edit';
    constructor(public payload: { id: number; site: Site }) { }
}

export class GetSiteById {
    static readonly type = '[Site] GetSite';
    constructor(public payload: { id: number }) { }
}

export class GetAllSites {
    static readonly type = '[Site] GetAllSites';
    constructor() { }
}

export class DeleteSite {
    static readonly type = '[Site] DeleteSite';
    constructor(public payload: { id: number }) { }
}
export class ResetSite {
    static readonly type = '[Site] ResetSite';
}

export class GetAllSiteCategories {
    static readonly type = '[Site] Categories';
}

export class GetAllSiteTypes {
    static readonly type = '[Site] Types';
}

export class GetAllSiteRisks {
    static readonly type = '[Site] Risks';
}

export class GetRiskFrequency {
    static readonly type = '[Site] Risk frequency';
}

export class GetRiskGravity {
    static readonly type = '[Site] Risk gravity';
}

export class GetRiskManagement {
    static readonly type = '[Site] Risk management';
}

export class GetRiskProbability {
    static readonly type = '[Site] Risk probability';
}

export class CreateRiskAnalyse {
    static readonly type = '[Site] Create Risk Analyse';
    constructor(public payload: { analyse: IAnalyse }) { }
}

export class UpdateRiskAnalyse {
    static readonly type = '[Site] Update Risk Analyse';
    constructor(public payload: { analyse: IAnalyseOut; id: number }) { }
}

export class GetAllRiskAnalysis {
    static readonly type = '[Site] Get Risk Analyse';
    constructor(public payload: { siteId: number }) { }
}

export class ResetRiskAnalysis {
    static readonly type = '[Site] Reset analysis';
}

export class SetSiteFilter {
    static readonly type = '[Site] Filter';

    constructor(public payload: { filter: string }) { }
}

export class ResetSiteFilter {
    static readonly type = '[Site] Reset Filter';
}

export class UpdateRisk {
    static readonly type = '[Risk] Update risk';
    constructor(public risks: IRiskDtoOut[]) { }
}

export class GetAggravatedRisks {
    static readonly type = '[Risk] Agravated risk';
}
