/**
 *
 *
 * @private
 * @param {string} key
 * @param {*} value
 * @param {FormData} formData
 * @return {*}  {void}
 * @memberof SiteService
 *
 * Potential global function to append formData to a formData object from form values
 */
export function appendToFormData(key: string, value: any, formData: FormData): void {
    if (value === undefined) {
        return;
    }

    if (Array.isArray(value)) {
        value.forEach(element => appendToFormData(`${key}[]`, element, formData));

        return;
    }

    if (typeof value === 'string' || value instanceof File) {
        formData.append(key, value);

        return;
    }

    if (typeof value === 'object' && value !== null) {
        for (const objKey of Object.keys(value)) {
            appendToFormData(`${key}[${objKey}]`, value[objKey], formData);
        }

        return;
    }

    formData.append(key, JSON.stringify(value));
}
