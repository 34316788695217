<div *ngIf="filteredParticipants" class="dropdown__wrapper" appClickOutside (clickOutEvent)="isDropdownOpen = false">
    <div class="dropdown">
        <div class="search-input__wrapper">
            <input class="search-input custom-select" [placeholder]="placeholder" #searchInput type="text"
                (input)="onSearch(searchInput.value)" (blur)="onBlur()" [value]="textInputValue" [disabled]="disabled"
                (click)="onClick($event)" (keydown)="onInputKeyPress($event)" [readonly]="selectedId"
                [ngClass]="{'contains-selection': selectedId, 'is-invalid': isInputValid}">
            <span class="icon icon-cross search-input__reset" *ngIf="selectedId"
                (click)="onResetClick(searchInput)"></span>
        </div>
        <ul *ngIf="isDropdownOpen" class="options" [attr.aria-expanded]="isDropdownOpen">
            <li class="option" #option (click)="onOptionClick(null)" (keydown)="onOptionKeyPress($event, null)"
                tabindex="0">Aucun</li>
            <li class="option" #option *ngFor="let participant of filteredParticipants"
                (click)="onOptionClick(participant)" (keydown)="onOptionKeyPress($event, participant)" tabindex="0">
                {{participant.firstname}} {{participant.lastname}} ({{participant.company}})
            </li>
        </ul>
        <div *ngIf="ngControl.hasError('uncleanField')" class="invalid-feedback">
            <small>
                Ajoutez un {{placeholder}} ou videz le champ
            </small>
        </div>
    </div>
</div>
