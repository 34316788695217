import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    @Output() clickOutEvent = new EventEmitter<boolean>();

    @HostListener('document:click', ['$event.target']) onClick(targetElement) {
        const clicked = this.elementRef.nativeElement.contains(targetElement);

        if (!clicked) {
            this.clickOutEvent.emit(true);
        }
    }

    constructor(private elementRef: ElementRef) {

    }



}
