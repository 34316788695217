import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { PageTitleState } from './../../../shared/store/state/page-title.state';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {
    @Select(PageTitleState.getPageTitle) pageTitle$: Observable<string>;

    constructor() {
    }

}
