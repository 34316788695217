import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SetPageTitle } from '../actions/page-title.actions';

export interface PageTitleModel {
    name: string;
}

@State<PageTitleModel>({
    name: 'pageTitle',
    defaults: {
        name: ''
    }
})
@Injectable()
export class PageTitleState {

    constructor() { }


    @Selector()
    static getPageTitle(state: PageTitleModel) {
        return state.name;
    }

    @Action(SetPageTitle)
    setPageTitle(ctx: StateContext<PageTitleModel>, action: SetPageTitle): void {
        //  Allows to fix ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. error
        setTimeout(() => {
            ctx.patchState({ name: action.name });
        }, 0)
    }
}
