import { IdName } from 'src/shared/interfaces/global.interface';

import { ISite } from '../dtos/ISite.interface';
import { Adress } from './adress.model';
import { HospitalAddressModel } from './hospital-address.model';
import { PartialParticipant } from './partial-participant.model';

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class Site {
    id: number;
    name: string;
    workType: string;
    startDate: string;
    endDate: string;
    area: boolean;
    plannedVisits: number;
    siteCategoryId: number;
    siteTypeId: number;
    completionLevel: number;
    bigEstimatedBudget: boolean;
    longEstimatedDuration: boolean;
    lotSimultaneousWorkers: boolean;
    manHours: string;
    moreThan3Enterprises: boolean;
    increasedRisks: boolean;
    updatedAt?: string;
    adress: Adress;
    implantationImage?: File | string;
    locationImage: File | string;
    homepageImage: File | string;
    hospitalMapImage: File | string;
    containsVisits?: number;
    partialParticipants: PartialParticipant[];
    hospitalAddress: HospitalAddressModel;
    workList: string[];
    aggravatedRisks: IdName[];

    constructor(base: Partial<Site>) {
        this.initialize();
        Object.assign(this, base);
    }

    public static fromDto(site: ISite): Site {
        return new Site({
            id: (site && site.id) || undefined,
            name: (site && site.name) || '',
            workType: (site && site.work_type) || undefined,
            startDate: (site && site.start_date) || null,
            endDate: (site && site.end_date) || null,
            area: (site && site.area) || false,
            completionLevel: (site && site.completion_level) || 0,
            plannedVisits: (site && site.planned_visits) || 0,
            siteCategoryId: (site && site.site_category_id) || undefined,
            siteTypeId: (site && site.site_type_id) || undefined,
            bigEstimatedBudget: (site && site.big_estimated_budget) || undefined,
            longEstimatedDuration: (site && site.long_estimated_duration) || undefined,
            lotSimultaneousWorkers: (site && site.lot_simultaneous_workers) || undefined,
            manHours: (site && site.men_hours) || undefined,
            moreThan3Enterprises: (site && site.more_than_3_enterprise) || undefined,
            increasedRisks: (site && site.increased_risks) || undefined,
            containsVisits: (site && site.contains_visits) || null,
            updatedAt: (site && site.updated_at) || null,
            adress: site && site.adress ? Adress.fromDto(site.adress) : new Adress({}),
            partialParticipants:
                site && site.participants_sites_attributes
                    ? site.participants_sites_attributes.map((x) => PartialParticipant.fromDto(x))
                    : new Array<PartialParticipant>(),
            implantationImage: (site && site.implantation_image) || null,
            locationImage: (site && site.location_image) || null,
            homepageImage: (site && site.homepage_image) || null,
            hospitalMapImage: (site && site.hopital_map_image) || null,
            workList: (site && site.work_list) || null,
            hospitalAddress: new HospitalAddressModel(site?.hopital_adress),
            aggravatedRisks: site && site.aggravated_risks ? site.aggravated_risks : [],
        });
    }

    public toDto(): ISite {
        return {
            id: this.id,
            name: this.name,
            work_type: this.workType,
            site_type_id: this.siteTypeId,
            site_category_id: this.siteCategoryId,
            start_date: this.startDate,
            end_date: this.endDate,
            area: this.area,
            planned_visits: this.plannedVisits,
            big_estimated_budget: this.bigEstimatedBudget,
            long_estimated_duration: this.longEstimatedDuration,
            lot_simultaneous_workers: this.lotSimultaneousWorkers,
            men_hours: this.manHours,
            more_than_3_enterprise: this.moreThan3Enterprises,
            increased_risks: this.increasedRisks,
            adress_attributes: {
                postcode: this.adress.postcode,
                city: this.adress.city,
                street: this.adress.street,
                number: this.adress.number,
            },
            contains_visits: this.containsVisits,
            work_list: this.workList,
            implantation_image: this.implantationImage,
            location_image: this.locationImage,
            homepage_image: this.homepageImage,
            hopital_map_image: this.hospitalMapImage,
            hopital_adress_attributes: this.hospitalAddress,
            participants_sites_attributes: this.partialParticipants.map((x) => PartialParticipant.toDto(x)),
            aggravated_risk_ids: this.aggravatedRisks ? this.aggravatedRisks.map((risk) => risk.id) : [],
        };
    }

    private initialize() {
        this.id = undefined;
        this.name = undefined;
        this.workType = undefined;
        this.startDate = null;
        this.endDate = null;
        this.area = false;
        this.plannedVisits = 0;
        this.siteCategoryId = undefined;
        this.siteTypeId = undefined;
        this.bigEstimatedBudget = undefined;
        this.longEstimatedDuration = undefined;
        this.lotSimultaneousWorkers = undefined;
        this.manHours = undefined;
        this.moreThan3Enterprises = undefined;
        this.increasedRisks = undefined;
        this.updatedAt = null;
        this.adress = new Adress({});
        this.containsVisits = undefined;
        this.partialParticipants = new Array<PartialParticipant>();
    }
}
