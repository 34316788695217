/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IRiskDtoOut } from 'src/views/sites/interfaces/risk.interface';

import {
    CreateActivity,
    DeleteActivity,
    EditActivity,
    GetActivityById,
    GetAllActivities,
    ResetActivity,
} from '../actions/activity.action';
import { IActivity, IActivityDtoOut } from './../../../views/activities/DTO/activity.interface';
import { ActivityService } from './../../../views/activities/services/activity.service';

export class ActivitiesStateModel {
    public activities: IActivity[];
    public activity: IActivity;
    public isListFetched: boolean;
}

@State<ActivitiesStateModel>({
    name: 'activities',
    defaults: {
        activities: [],
        activity: null,
        isListFetched: false,
    },
})
@Injectable()
export class ActivityState {
    constructor(private activityService: ActivityService) {}

    @Selector()
    public static getAllActivities(state: ActivitiesStateModel): IActivity[] {
        return state.activities;
    }

    @Selector()
    public static getActivityById(state: ActivitiesStateModel): IActivity {
        return state.activity;
    }

    @Action(CreateActivity)
    public create(ctx: StateContext<ActivitiesStateModel>, action: CreateActivity): Observable<any> {
        return this.activityService.create(action.activity).pipe(
            tap((result: IActivity) => {
                ctx.setState(
                    patch({
                        activities: append([result]),
                        activity: result,
                    })
                );
            })
        );
    }

    @Action(EditActivity)
    public editActivity(ctx: StateContext<ActivitiesStateModel>, action: EditActivity): Observable<any> {
        const processedUpdatedActivity = this.getProcessedUpdatedActivity(action.updatedActivity, action.oldActivity);

        return this.activityService.edit(processedUpdatedActivity).pipe(
            tap((result: IActivity) => {
                ctx.setState(
                    patch({
                        activities: updateItem<IActivity>((activity) => activity.id === result.id, result),
                        activity: result,
                    })
                );
            })
        );
    }

    @Action(GetActivityById)
    public getActivityById(ctx: StateContext<ActivitiesStateModel>, action: GetActivityById): Observable<any> {
        return this.activityService.getActivityById(action.id).pipe(
            tap((result) => {
                ctx.patchState({
                    activity: result,
                });
            })
        );
    }

    @Action(GetAllActivities)
    public getAllActivities(ctx: StateContext<ActivitiesStateModel>): Observable<any> {
        return this.activityService.getAllActivities().pipe(
            tap((result) => {
                ctx.patchState({
                    activities: result,
                    isListFetched: true,
                });
            })
        );
    }

    @Action(DeleteActivity)
    public deleteActivity(ctx: StateContext<ActivitiesStateModel>, action: DeleteActivity): Observable<any> {
        return this.activityService.deleteActivity(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        activities: removeItem<IActivity>((activity) => activity.id === action.id),
                        activity: null,
                    })
                );
            })
        );
    }

    @Action(ResetActivity)
    public resetActivity(ctx: StateContext<ActivitiesStateModel>): void {
        ctx.patchState({
            activity: null,
            activities: [],
            isListFetched: false,
        });
    }

    /*
    Since we have to manually send _destroy for activity risks that were deleted
    In the update, iterate over the old activities, if the old risk attribute is still
    Present in the new, updated activity, just return a new RiskModel, else, return it with
    the _destroy boolean flipped to true
    */
    private getProcessedUpdatedActivity(updatedActivity: IActivityDtoOut, oldActivity: IActivity): IActivityDtoOut {
        const updatedRisks: IRiskDtoOut[] = oldActivity.risk_ids.map((rid) => {
            const riskToUpdate = updatedActivity.risks_attributes.find((risk) => risk.id === rid || risk === null);
            // Edit bug

            if (riskToUpdate) {
                return {
                    id: riskToUpdate.id,
                    complete_risk: riskToUpdate.complete_risk,
                    simplified_risk: riskToUpdate.simplified_risk,
                    measure_ids: riskToUpdate.measure_ids,
                    _destroy: 0,
                };
            }

            return {
                id: rid,
                _destroy: 1,
            } as IRiskDtoOut;
        });

        /* Also loop over updatedRisks to add those that were created in the edit
        as they have no risk.id yet */
        updatedActivity.risks_attributes.forEach((risk) => {
            if (risk !== null) {
                updatedRisks.push(risk);
            }
        });

        return {
            id: updatedActivity.id,
            name: updatedActivity.name,
            risks_attributes: updatedRisks,
        };
    }
}
