import { HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { IActivity } from 'src/views/activities/DTO/activity.interface';

import { mockActivities } from './mock-data';

export const mockActivitiesRes = (request: HttpRequest<any>) => {
    const data = mockActivities;

    // Check if there is an id at the end of the URL
    const idMatch = request.url.match(/(?<id>\d+)$/);

    if (request.method === 'GET') {
        let response;

        if (idMatch) {
            const id = parseInt(idMatch.groups?.id, 10) || null;
            const activityToReturn = data.find(activity => activity.id === id) || null;

            response = {
                status: 200,
                body: { ...activityToReturn }
            };
        } else {
            response = {
                status: 200,
                body: { activities: data },
            };
        }

        return of(new HttpResponse(response));
    }

    if (request.method === 'POST') {
        const id = Math.floor(Math.random() * 1000);

        const newActivity: Partial<IActivity> = request.body;

        newActivity.id = id;

        if (newActivity.risk_ids) {
            newActivity.risk_ids.forEach(attribute => {
                const newRiskId = Math.floor(Math.random() * 1000);

                attribute = newRiskId;
            });
        }

        const response = {
            status: 200,
            body: request.body
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'PUT') {
        const updatedRisks = request.body.risks_attributes.map(risk => risk._destroy ? null : risk);


        const updatedActivity = { ...request.body, risks_attributes: updatedRisks.filter(risk => risk !== null) };

        const response = {
            status: 200,
            body: updatedActivity
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'DELETE') {
        const response = {
            status: 204
        };

        return of(new HttpResponse(response));
    }

    return of(new HttpResponse());
};
