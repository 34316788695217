import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs-compat';
import { tap } from 'rxjs/operators';

import { VisitingReportService } from '../../../views/sites/services/visiting-report.service';
import {
    CreateVisitingReport,
    DeleteVisitingReport,
    GetVisitingReportById,
    GetVisitingReportsBySiteId,
    ResetVisits,
    UpdateVisitingReport,
} from '../actions/visiting-report.action';
import { IVisitReport } from './../../../views/sites/interfaces/reports.interface';
import { ResetVisit } from './../actions/visiting-report.action';

export class VisitingReportsStateModel {
    public visitingReports: IVisitReport[];
    public visitingReport: IVisitReport;
    public isListFetched: boolean;
}

@State<VisitingReportsStateModel>({
    name: 'VisitingReports',
    defaults: {
        visitingReports: [],
        visitingReport: null,
        isListFetched: false,
    },
})
@Injectable()
export class VisitingReportsState {
    constructor(private visitingReportService: VisitingReportService) {}

    @Selector()
    public static getVisitingReportsBySiteId(state: VisitingReportsStateModel): IVisitReport[] {
        return state.visitingReports;
    }

    @Selector()
    public static getVisitingReportById(state: VisitingReportsStateModel): IVisitReport {
        return state.visitingReport;
    }

    @Action(GetVisitingReportsBySiteId)
    public getVisitingReportsBySiteId(ctx: StateContext<VisitingReportsStateModel>, action: GetVisitingReportsBySiteId): Observable<any> {
        return this.visitingReportService.getVisitingReportsBySiteId(action.payload.siteId).pipe(
            tap((result) => {
                ctx.patchState({
                    visitingReports: result,
                    isListFetched: true,
                });
            })
        );
    }

    @Action(GetVisitingReportById)
    public getVisitingReportById(ctx: StateContext<VisitingReportsStateModel>, action: GetVisitingReportById): Observable<any> {
        return this.visitingReportService.getVisitingReportById(action.payload.id).pipe(
            tap((result) => {
                ctx.patchState({
                    visitingReport: result,
                });
            })
        );
    }

    @Action(CreateVisitingReport)
    public createVisitingReport(ctx: StateContext<VisitingReportsStateModel>, action: CreateVisitingReport): Observable<any> {
        return this.visitingReportService.createVisitingReport(action.payload.visitingReport).pipe(
            tap((result: IVisitReport) => {
                ctx.setState(
                    patch({
                        visitingReports: append([result]),
                    })
                );
            })
        );
    }

    @Action(UpdateVisitingReport)
    public updateVisitingReport(ctx: StateContext<VisitingReportsStateModel>, action: UpdateVisitingReport): Observable<any> {
        return this.visitingReportService.updateVisitingReport(action.payload.visitingReport).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        visitingReports: updateItem<IVisitReport>((report) => report.id === result.id, result),
                    })
                );
            })
        );
    }

    @Action(DeleteVisitingReport)
    public deleteVisitingReport(ctx: StateContext<VisitingReportsStateModel>, action: DeleteVisitingReport): Observable<any> {
        return this.visitingReportService.deleteVisitingReport(action.payload.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        visitingReports: removeItem<IVisitReport>((report) => report.id === action.payload.id),
                    })
                );
            })
        );
    }

    @Action(ResetVisit)
    public resetVisit(ctx: StateContext<VisitingReportsStateModel>): void {
        ctx.setState(
            patch({
                visitingReport: null,
            })
        );
    }

    @Action(ResetVisits)
    public resetVisits({ setState }: StateContext<VisitingReportsStateModel>): void {
        setState({
            visitingReport: null,
            visitingReports: [],
            isListFetched: false,
        });
    }
}
