<alert class="alert__container" type="{{type$ | async}}" (click)=" onClick()"
    [ngClass]="(infoMsg$ | async) ? 'active' : ''">
    <div class="alert__content">
        <span *ngIf="(type$ | async) === alertTypes.SUCCESS" class="icon icon-checkmark"></span>
        <p>{{infoMsg$ | async}}</p>
        <button class="btn alert__close" aria-label="close alert">
            <span class="icon icon-cross"></span>
        </button>
    </div>
</alert>
