import { UserModel } from '../../../views/users/models/user.model';

export class GetAllUsers {
    static readonly type = '[Users] Get All Users';
}

export class GetUserById {
    static readonly type = '[Users] Get User By Id';

    constructor(public id: number) { }
}

export class CreateUser {
    static readonly type = '[Users] Create User';

    constructor(public user: UserModel) { }
}

export class UpdateUser {
    static readonly type = '[Users] Update User';

    constructor(public user: UserModel) { }
}

export class DeleteUser {
    static readonly type = '[Users] Delete User';

    constructor(public id: number) { }
}

export class SetUserFilter {
    static readonly type = '[Users] Filter User';

    constructor(public filter: string) { }
}

export class ResetUserFilter {
    static readonly type = '[Users] Reset Filter';
}
