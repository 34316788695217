/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-input-image',
    templateUrl: './input-image.component.html',
    styleUrls: ['./input-image.component.scss'],
})
export class InputImageComponent {
    @Input() public imageFormArray: FormArray;

    constructor(public fb: FormBuilder) {}

    public uploadImage(e: any): void {
        const file = (e.target as HTMLInputElement).files[0];

        const reader = new FileReader();
        reader.onload = () => {
            const url = reader.result as string;
            this.addImageToForm(url, file);
        };
        reader.readAsDataURL(file);
    }

    public removeImage(index: number): void {
        this.imageFormArray.at(index).patchValue({
            _destroy: [true],
        });
        //this.imageFormArray.removeAt(index);
    }

    private addImageToForm(url: string, img: File, id?: number): void {
        const newImage: FormGroup = this.fb.group({
            id: [id ? id : null],
            image: [img],
            url: [url],
            _destroy: [false],
        });
        this.imageFormArray.insert(this.imageFormArray.length, newImage);
    }
}
