import { IIntervenant } from './../DTO/intervenant.interface';

export class IntervenantModel {
  id: number;
  lastname: string;
  firstname: string;
  email: string;
  phoneNumber: string;
  company: string;
  companyAddress?: string;


  // TODO: Remove old properties
  name: string;
  role_id: number;
  _destroy = false;

  constructor(base: Partial<IntervenantModel>) {
    Object.assign(this, base);
  }

  public fromDto?(dto: IIntervenant): IntervenantModel {
    this.id = dto && dto.id ? dto.id : null;
    this.firstname = dto && dto.firstname ? dto.firstname : null;
    this.lastname = dto && dto.lastname ? dto.lastname : null;
    this.email = dto && dto.email ? dto.email : null;
    this.phoneNumber = dto && dto.phone ? dto.phone : null;
    this.company = dto && dto.enterprise ? dto.enterprise : null;
    this.companyAddress = dto && dto.enterprise_address ? dto.enterprise_address : null;

    return this;
  }

  public toDto?(): IIntervenant {
    const backendObj: IIntervenant = {
      id: this.id ? this.id : null,
      firstname: this.firstname ? this.firstname : null,
      lastname: this.lastname ? this.lastname : null,
      email: this.email ? this.email : null,
      phone: this.phoneNumber ? this.phoneNumber : null,
      enterprise: this.company ? this.company : null,
      enterprise_address: this.companyAddress ? this.companyAddress : null
    };

    return backendObj;
  }
}

