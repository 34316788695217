import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubUrlEnum } from 'src/shared/enums/sub-urls-enum';

import { environment } from './../../../environments/environment';
import { IProgress } from './../interfaces/progress.interface';

@Injectable({
    providedIn: 'root'
})
export class ProgressService {

    constructor(private http: HttpClient) { }

    getProgress(): Observable<IProgress> {
        return this.http.get<IProgress>(environment.API_URL + SubUrlEnum.SITE_PROGRESS);
    }
}
