import { IntervenantModel } from '../../../views/intervenants/models/intervenant.model';

export class GetAllIntervenants {
    static readonly type = '[Intervenants] Get All Intervenants';
}

export class GetIntervenantById {
    static readonly type = '[Intervenants] Get Intervenant By Id';

    constructor(public id: number) { }
}

export class CreateIntervenant {
    static readonly type = '[Intervenants] Create Intervenant';

    constructor(public intervenant: IntervenantModel) { }
}

export class UpdateIntervenant {
    static readonly type = '[Intervenants] Update Intervenant';

    constructor(public intervenant: IntervenantModel) { }
}

export class DeleteIntervenant {
    static readonly type = '[Intervenants] Delete Intervenant';

    constructor(public id: number) { }
}