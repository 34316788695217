import { ConventionType } from '../../../views/sites/enums/site-convention-type.enum';

export class GetAllConvention {
    static readonly type = '[Convention] Get all';
    constructor(public payload: { siteId: string }) {}
}

export class ResetConventions {
    static readonly type = '[Convention] Reset';
}

export class GenerateConvention {
    static readonly type = '[Convention] Create';
    constructor(public payload: { type: ConventionType; siteId: string }) {}
}

export class SendConventionByMail {
    static readonly type = '[Convention] Send mail';
    constructor(public payload: { conventionId: number }) {}
}

export class AddSignedConvention {
    static readonly type = '[Convention] Signed convention';
    constructor(public payload: { siteId: number; conventionId: number; conventionSigned: File }) {}
}
