<div class="container">
    <div class="row">
        <div class="col">
            <span class="badge badge-primary">{{ progressBarTitle }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <progressbar [class]="'progress-striped-active lvl-' + (level + 1)" [value]="progress" [striped]="true" [max]="max">
                {{ level + 1 }}/6
            </progressbar>
        </div>
    </div>
</div>
