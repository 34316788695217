import { IEnvironment } from './../shared/interfaces/environment.interface';

export const environment: IEnvironment = {
    production: false,
    API_KEY: '697cd63a',
    BASE_URL: 'https://symbiose.dev.itw.dgnus.net:8080/',
    API_URL: 'https://symbiose.dev.itw.dgnus.net:8080/api/',
    mock: {
        all: false,
        enable: true,
        services: {
            progress: true,
            offers: false,
            sites: false,
            activities: false,
            jobs: false,
            analysis: false,
        },
    },
};
