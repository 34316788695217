import { HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ISite } from 'src/views/sites/dtos/ISite.interface';

import { mockSites } from './mock-data';

export const mockSitesRes = (request: HttpRequest<any>) => {
    const siteData = mockSites;

    if (request.method === 'GET') {
        const response = {
            status: 200,
            body: { sites: siteData },
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'POST') {
        const newId = Math.floor(Math.random() * 1000);

        const newSite: Partial<ISite> = { ...request.body };
        newSite.id = newId;

        // Backend changes these properties names between receiving and sendind, so I mock that as well
        newSite.adress = newSite.adress_attributes;
        newSite.hopital_adress = newSite.hopital_adress_attributes;

        delete newSite.adress_attributes;
        delete newSite.hopital_adress_attributes;

        const response = {
            status: 200,
            body: newSite
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'PUT') {
        const mockItineraryUrl = 'https://www.wanarun.net/static/3/8/5/3858bdcf1719872135982728edcd3c89.png';

        const updatedSite: Partial<ISite> = { ...request.body };

        // Backend changes these properties names between receiving and sendind, so I mock that as well
        updatedSite.adress = updatedSite.adress_attributes;
        updatedSite.hopital_adress = updatedSite.hopital_adress_attributes;

        delete updatedSite.adress_attributes;
        delete updatedSite.hopital_adress_attributes;

        updatedSite.implantation_image = mockItineraryUrl;
        updatedSite.location_image = mockItineraryUrl;
        updatedSite.homepage_image = mockItineraryUrl;
        updatedSite.hopital_map_image = mockItineraryUrl;

        const response = {
            status: 200,
            body: updatedSite
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'DELETE') {
        const response = {
            status: 204,
        };

        return of(new HttpResponse(response));
    }
    return of(new HttpResponse());
};
