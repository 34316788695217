import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HeaderErrorEnum } from '../../../shared/enums/header-errors.enum';
import { SubUrlEnum } from './../../../shared/enums/sub-urls-enum';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient) { }

    login(email: string, password: string): Observable<any> {
        const headers = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.post<any>(environment.API_URL + SubUrlEnum.LOGIN, { email, password }, { headers });
    }

    refreshToken(accessToken: string, refreshToken: string): Observable<any> {
        const httpHeaders = new HttpHeaders()
            .set('Refresh-Token', refreshToken)
            .set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.post<any>(environment.API_URL + SubUrlEnum.REFRESH_TOKEN, null, { headers: httpHeaders });
    }

    logout(token: string) {
        const httpHeaders = new HttpHeaders()
            .set('Authorization', 'Bearer ' + token);
        return this.http.delete(environment.API_URL + SubUrlEnum.LOGOUT, { headers: httpHeaders });
    }
}
