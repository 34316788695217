import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    Self,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from 'src/shared/abstracts/base-component.abstract';
import { IntervenantModel } from 'src/views/intervenants/models/intervenant.model';

@Component({
    selector: 'app-filterable-dropdown',
    templateUrl: './filterable-dropdown.component.html',
    styleUrls: ['./filterable-dropdown.component.scss'],
})
export class FilterableDropdownComponent extends BaseComponent implements ControlValueAccessor, OnInit, OnChanges {
    /*
        This component is currently only filtering participants (see input participants below).
        It could "easily" be abstracted by passing more general data to filter via Input and
        modify the filterParticipants method to filterData, with checks on the type of data
        to decide which fields to filter on.
    */
    @ViewChildren('option') optionItems: QueryList<ElementRef>;

    @Input() placeholder: string;
    @Input() participants: IntervenantModel[];

    public filteredParticipants: IntervenantModel[];
    public isDropdownOpen: boolean;
    public textInputValue: string = '';
    public disabled = false;
    public onChange: (participantId: number) => void;
    public onTouched: () => void;
    public selectedId: number;

    private searchTerms: Subject<string>;
    private touched = false;

    /*
        I want to access the validation status of the formControl, for that I need a reference
        To the formCOntrol instance, which I can get in the constructor. That means I have to remove the
        NG_VALUE_ACCESSOR from the providers array because it would throw a cyclic dependency error.
        I then need to setup ngControl correctly with the right valueAccessor (set to this)
        See https://stackoverflow.com/questions/45755958/how-to-get-formcontrol-instance-from-controlvalueaccessor/56061527
    */
    constructor(@Self() public ngControl: NgControl) {
        super();

        ngControl.valueAccessor = this;
    }

    ngOnInit(): void {
        this.searchTerms = new Subject<string>();
        this.isDropdownOpen = false;

        this.uns = this.searchTerms
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((searchTerm: string) => this.filterParticipants(searchTerm));

        // Set custom validators
        const control = this.ngControl.control;
        const validators = control.validator ? [control.validator, this.cleanFieldValidator()] : this.cleanFieldValidator();
        control.setValidators(validators);
        control.updateValueAndValidity();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.participants && changes.participants.currentValue) {
            this.participants = changes.participants.currentValue;
            this.filteredParticipants = changes.participants.currentValue;
            if (this.selectedId) {
                const participant = this.participants.find((item) => item.id === this.selectedId) || null;
                this.setTextInputValue(participant);
            }
        }
    }

    public get isInputValid(): boolean {
        return !this.ngControl.control.valid && this.ngControl.control.touched && !this.isDropdownOpen;
    }

    public onSearch(term: string) {
        if (!this.disabled) {
            this.searchTerms.next(term.trim().toLowerCase());
            this.textInputValue = term;
        }
    }

    public onClick(event: MouseEvent): void {
        this.toggleListVisibility(event);
    }

    public onInputKeyPress(event: KeyboardEvent): void {
        this.toggleListVisibility(event);

        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            this.focusFirstOption();
        }
    }

    public onBlur(): void {
        this.markAsTouched();
    }

    public onOptionClick(participant: IntervenantModel): void {
        if (!participant) {
            return this.resetFilters();
        }

        this.selectParticipant(participant);
    }

    public onOptionKeyPress(event: KeyboardEvent, participant: IntervenantModel): void {
        switch (event.key) {
            case 'Enter':
                // prevents form from sending with enter
                event.preventDefault();
                this.resetFilters();
                break;

            case 'ArrowDown':
                this.focusNextListItem('down', event.target as HTMLUListElement);

                // prevents page from scrolling
                event.preventDefault();
                break;

            case 'ArrowUp':
                this.focusNextListItem('up', event.target as HTMLUListElement);
                event.preventDefault();
                break;

            case 'Escape':
                this.closeList();
                break;
        }
    }

    ////////////////////////////////////
    // Control value accessor methods //
    ////////////////////////////////////
    public registerOnChange(fn) {
        this.onChange = fn;
    }

    public writeValue(participantId: number): void {
        this.selectedId = participantId;
        if (this.participants.length) {
            const participant = this.participants.find((item) => item.id === this.selectedId) || null;
            this.setTextInputValue(participant);
        }
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
    //////////////////////////////////////////////////
    // End control value accessor required methods ///
    //////////////////////////////////////////////////

    public selectParticipant(participant: IntervenantModel): void {
        if (this.disabled) {
            return;
        }

        this.setTextInputValue(participant);

        this.selectedId = participant ? participant.id : null;

        this.onChange(this.selectedId);
        this.closeList();
    }

    public onResetClick(searchInput: HTMLInputElement): void {
        this.resetFilters();

        // SetTimeout allows element to be enabled again to be focused
        setTimeout(() => {
            searchInput.focus();
            this.isDropdownOpen = true;
        }, 0);
    }
    public resetFilters(): void {
        this.filteredParticipants = this.participants;
        this.selectedId = null;
        this.textInputValue = null;

        this.closeList();
        // notify the form we're removing the participant
        this.onChange(null);
    }

    private filterParticipants(searchTerm: string) {
        const preparedTerms: string[] = searchTerm.split(' ');
        this.filteredParticipants = this.participants.filter((participant: IntervenantModel) => {
            const stringToFilterOn = [
                participant.firstname?.toLowerCase() || '',
                participant.lastname?.toLowerCase() || '',
                `(${participant.company.toLowerCase() || ''})`,
            ].join(' ');

            const isRelevant = preparedTerms.every((term) => stringToFilterOn.indexOf(term) !== -1);

            return isRelevant;
        });

        /*
        Notify the parent form we're changing the value of the search input, necessary
        for the cleanFieldValidator below to run, even though we're not really changing the form value
        */
        this.onChange(null);
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    private toggleListVisibility(event: MouseEvent | KeyboardEvent): void {
        if (event instanceof MouseEvent) {
            this.isDropdownOpen = !this.isDropdownOpen;
        }

        if (event instanceof KeyboardEvent) {
            if (event.key === 'Enter') {
                this.isDropdownOpen = true;

                // prevents form from sending
                event.preventDefault();
            }

            if (event.key === 'Escape') {
                this.closeList();
            }
        }
    }

    private focusNextListItem(direction: string, listItem: HTMLUListElement) {
        switch (direction) {
            case 'up':
                if (listItem.previousElementSibling) {
                    (listItem.previousElementSibling as HTMLUListElement).focus();
                }
                break;
            case 'down':
                if (listItem.nextElementSibling) {
                    (listItem.nextElementSibling as HTMLUListElement).focus();
                }
                break;
        }
    }

    private closeList() {
        this.isDropdownOpen = false;
    }

    private focusFirstOption() {
        if (this.isDropdownOpen) {
            this.optionItems.first.nativeElement.focus();
        }
    }

    private setTextInputValue(participant: IntervenantModel): void {
        const participantString = participant ? `${participant?.firstname} ${participant?.lastname} (${participant?.company})` : '';
        this.textInputValue = participantString;
    }

    private cleanFieldValidator(): ValidatorFn {
        return (): { [key: string]: any } | null => {
            const invalid = !!(this.textInputValue && !this.selectedId);

            return invalid ? { uncleanField: true } : null;
        };
    }
}
