import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IStat } from './../../../views/dashboard/dto/statDto';
import { StatsService } from './../../../views/dashboard/services/stats.services';
import { GetStats } from './../actions/stats.action';

export class StatsStateModel {
    public stats: IStat;
    public isListFetched: boolean;
}
@State<StatsStateModel>({
    name: 'stats',
    defaults: {
        stats: null,
        isListFetched: false,
    },
})
@Injectable()
export class StatsState {
    constructor(private statsService: StatsService) {}

    @Selector()
    public static getStats(state: StatsStateModel): IStat {
        return state.stats;
    }

    @Action(GetStats)
    public getStats(ctx: StateContext<StatsStateModel>): Observable<any> {
        return this.statsService.getStats().pipe(
            tap((result) => {
                ctx.patchState({
                    stats: result,
                    isListFetched: true,
                });
            })
        );
    }
}
