import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from 'src/shared/abstracts/form-control.abstract';

import { FileTypeEnum } from './../../enums/file-type.enum';

@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FileInputComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => FileInputComponent),
        },
    ],
})
export class FileInputComponent extends FormControlComponent implements OnChanges, OnInit {
    @Input() label: string;
    @Input() fileUrl: string = null;
    @Input() type: string;

    @Output() fileUrlChange = new EventEmitter<string>();

    public value: File = null;
    public fileTypeEnum = FileTypeEnum;
    /* The icon before the file */
    public iconType: string;
    /* The accepted content type for the file */
    public accept: string;

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.setFileOptions();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.fileUrl && changes.fileUrl.currentValue) {
            this.fileUrl = changes.fileUrl.currentValue;
        }
    }

    public onFileSelect(event: Event): void {
        if (this.disabled) {
            return;
        }

        const file: File = (event.target as HTMLInputElement).files[0] || null;

        if (file) {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                this.fileUrl = reader.result.toString();
                this.fileUrlChange.emit(this.fileUrl);
            };
        } else {
            this.fileUrlChange.emit(this.fileUrl);
        }

        this.markAsTouched();
        this.value = file;
        this.onChange(this.value);
    }

    public onFileRemove(event: Event, input: HTMLInputElement): void {
        this.value = null;
        this.fileUrl = null;
        input.value = null;

        this.fileUrlChange.emit(this.fileUrl);
        this.onChange(this.value);
        event.preventDefault();
    }

    /*
        writeValue is not really used here since in edit form we don't write the fileUrl to the formControl, we just pass it
        to this custom form control as input
     */
    public writeValue(value: File): void {
        this.value = value;
    }

    public registerOnValidatorChange?(fn: () => void): void {
        this.validatorOnChange = fn;
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    private setFileOptions(): void {
        switch (this.type) {
            case FileTypeEnum.IMAGE:
                this.iconType = 'icon-image';
                this.accept = 'image/*';
                break;
            case FileTypeEnum.DEFAULT:
                this.iconType = 'icon-attachment';
                this.accept = '';
                break;
            case FileTypeEnum.OFFER:
                this.iconType = 'icon-attachment';
                this.accept =
                    'application/pdf, image/jpg, image/jpeg, image/png, application/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/excel, application/vnd.msexcel, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case FileTypeEnum.PDF:
                this.iconType = 'icon-attachment';
                this.accept = 'application/pdf';
        }
    }
}
