import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appendToFormData } from 'src/shared/utils/append-to-form-data';

import { environment } from '../../../environments/environment';
import { ISite } from '../dtos/ISite.interface';
import { SecurityPlan } from '../models/security-plan.model';
import { SiteCategory } from '../models/site-category.model';
import { SiteType } from '../models/site-type.model';
import { Site } from '../models/site.model';
import { VisitRisk } from '../models/visit-risk.model';
import { SubUrlEnum } from './../../../shared/enums/sub-urls-enum';

@Injectable({
    providedIn: 'root',
})
export class SiteService {
    constructor(private http: HttpClient) { }

    getAllSites(): Observable<Site[]> {
        return this.http
            .get<Site[]>(environment.API_URL + SubUrlEnum.SITES_LIST)
            .pipe(
                map((data: any) => {
                    return data
                        ? data.sites.map((element) => Site.fromDto(element))
                        : null;
                })
            );
    }

    getSiteById(siteId: string): Observable<Site> {
        return this.http
            .get<Site>(environment.API_URL + SubUrlEnum.SITE_DETAILS + siteId)
            .pipe(
                map((data: any) => {
                    return data ? Site.fromDto(data) : null;
                })
            );
    }

    deleteSite(siteId: number): Observable<Site> {
        return this.http.delete<Site>(
            environment.API_URL + SubUrlEnum.SITE_DELETE + siteId
        );
    }

    createSite(site: Site): Observable<Site> {
        const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data',
        });
        const newSite: FormData = this.toFormData(site.toDto());

        return this.http
            .post<ISite>(environment.API_URL + SubUrlEnum.SITE_ADD, newSite, {
                headers,
            })
            .pipe(
                map((result) => {
                    return Site.fromDto(result);
                })
            );
    }

    updateSite(site: Site): Observable<Site> {
        const headers = new HttpHeaders().set(
            'Content-Type',
            'multipart/form-data'
        );
        const siteToUpdate: FormData = this.toFormData(site.toDto());

        return this.http
            .put<ISite>(
                environment.API_URL + SubUrlEnum.SITE_UPDATE + site.id,
                siteToUpdate,
                { headers }
            )
            .pipe(
                map((result) => {
                    return Site.fromDto(result);
                })
            );
    }

    createSecurityPlan(pss: SecurityPlan): Observable<SecurityPlan> {
        return this.http
            .post<SecurityPlan>(
                SubUrlEnum.SECURITY_PLAN_ADD.replace(
                    '{id}',
                    pss.site_id.toString()
                ),
                pss
            )
    }

    updateSecurityPlan(pss: SecurityPlan): Observable<SecurityPlan> {
        return this.http
            .put<SecurityPlan>(
                SubUrlEnum.SECURITY_PLAN_UPDATE.replace(
                    '{id}',
                    pss.site_id.toString()
                ) + pss.id,
                pss
            )
    }

    getSecurityPlanBySiteId(siteId: string): Observable<SecurityPlan> {
        return this.http.get<SecurityPlan>(
            SubUrlEnum.SECURITY_PLAN_DETAILS.replace('{id}', siteId.toString())
        );
    }

    getVisitRisks(visitId: string): Observable<VisitRisk[]> {
        return this.http
            .get<VisitRisk[]>(
                SubUrlEnum.VISIT_RISK.replace('{id}', visitId.toString())
            )
            .pipe(map((data: any) => data.risks));
    }

    getCategories(): Observable<SiteCategory[]> {
        return this.http
            .get<SiteCategory[]>(environment.API_URL + SubUrlEnum.CATEGORIES)
            .pipe(map((data: any) => data.sites_categories));
    }

    getTypes(): Observable<SiteType[]> {
        return this.http
            .get<SiteCategory[]>(environment.API_URL + SubUrlEnum.TYPES)
            .pipe(map((data: any) => data.sites_types));
    }

    private toFormData(site: ISite): FormData {
        const formData = new FormData();

        for (const key of Object.keys(site)) {
            appendToFormData(`site[${key}]`, site[key], formData);
        }

        return formData;
    }
}
