import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IdTitle } from 'src/shared/interfaces/global.interface';
import { DIU } from 'src/views/sites/interfaces/diu.interface';

import { DiuService } from './../../../views/sites/services/diu.service';
import {
    DeleteDIU,
    GetAllDIU,
    GetDocumentById,
    GetDocumentTypes,
    ResetDIU,
    ResetDIUs,
    UpdateDocument,
    UploadDIU,
} from './../actions/diu.action';

export class DIUStateModel {
    public document: DIU;
    public documents: DIU[];
    public documentTypes: IdTitle[];
}

@State<DIUStateModel>({
    name: 'DIU',
    defaults: {
        document: undefined,
        documents: [],
        documentTypes: [],
    },
})
@Injectable()
export class DIUState {
    constructor(private diuService: DiuService) {}

    @Selector()
    public static getAllDIU(state: DIUStateModel): DIU[] {
        return state.documents;
    }

    @Selector()
    public static getDocumentTypes(state: DIUStateModel): IdTitle[] {
        return state.documentTypes;
    }

    @Selector()
    public static getDocument(state: DIUStateModel): DIU {
        return state.document;
    }

    @Action(GetAllDIU)
    public getAllDIU(ctx: StateContext<DIUStateModel>, action: GetAllDIU): Observable<any> {
        return this.diuService.getAllDIU(action.payload.siteId).pipe(
            tap((result) => {
                ctx.patchState({
                    documents: result,
                });
            })
        );
    }

    @Action(GetDocumentById)
    public getDocumentById(ctx: StateContext<DIUStateModel>, action: GetDocumentById): Observable<any> {
        return this.diuService.getDocumentById(action.payload.docId).pipe(
            tap((result) => {
                ctx.patchState({
                    document: result,
                });
            })
        );
    }

    @Action(UploadDIU)
    public uploadDIU(ctx: StateContext<DIUStateModel>, action: UploadDIU): void {
        const state = ctx.getState();
        this.diuService.uploadDocument(action.payload.doc).subscribe((newDoc) => {
            ctx.patchState({
                documents: [...state.documents, newDoc],
            });
        });
    }

    @Action(DeleteDIU)
    public deleteDIU(ctx: StateContext<DIUStateModel>, action: DeleteDIU): void {
        const state = ctx.getState();
        this.diuService.deleteDocument(action.payload.docId.toString()).subscribe(() => {
            ctx.patchState({
                documents: state.documents.filter((doc) => doc.id !== action.payload.docId),
            });
        });
    }

    @Action(ResetDIUs)
    public resetDIUs(ctx: StateContext<DIUStateModel>): void {
        ctx.patchState({
            documents: [],
        });
    }

    @Action(ResetDIU)
    public resetDIU(ctx: StateContext<DIUStateModel>): void {
        ctx.patchState({
            document: undefined,
        });
    }

    @Action(GetDocumentTypes)
    public getDocumentTypes(ctx: StateContext<DIUStateModel>): void {
        const state = ctx.getState();
        if (state.documentTypes.length) {
            return;
        }
        this.diuService.getDocumentTypes().subscribe((result) => {
            ctx.patchState({
                documentTypes: result,
            });
        });
    }

    @Action(UpdateDocument)
    public updateDocument(ctx: StateContext<DIUStateModel>, action: UpdateDocument): void {
        this.diuService.updateDocument(action.payload.docId, action.payload.doc).subscribe((result) => {
            ctx.setState(
                patch({
                    document: result,
                    documents: updateItem<DIU>((doc) => doc.id === +action.payload.docId, result),
                })
            );
        });
    }
}
