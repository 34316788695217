<div class="images-list">
    <div *ngFor="let img of images" class="images-item">
        <div class="image" (click)="showImage(img.url)" [ngClass]="{'big':img.url===imgInTheShow}">
            <img [src]="img.url">
        </div>
        <div class="delete" (click)="removeImage(img.id)">
            <span class="icon icon-cross"></span>
        </div>
    </div>
</div>
