import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { filter } from 'rxjs/operators';

import { BaseComponent } from '../shared/abstracts/base-component.abstract';
import { Logout } from './../shared/store/actions/auth.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
    public title = 'Symbi\'Ose';
    public hideNavigation = true;

    constructor(private actions: Actions, private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.redirectUserOnLogout();

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routingEvent: NavigationEnd) => {
            this.hideNavigation = routingEvent.url === '/login';
        });
    }

    /**
     * Redirect user to login page on Logout
     */
    private redirectUserOnLogout() {

        this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
            this.router.navigate(['/login']);
        });
    }
}
