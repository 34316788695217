import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { JobModel } from '../models/job.model';
import { SubUrlEnum } from './../../../shared/enums/sub-urls-enum';
import { IJobDto, IJobsDto } from './../dtos/job-dto.interface';

@Injectable({
    providedIn: 'root'
})
export class JobService {

    constructor(private http: HttpClient) { }

    create(job: JobModel): Observable<JobModel> {
        const jobDto = job.toDto();
        return this.http.post<IJobDto>(environment.API_URL + SubUrlEnum.JOB_ADD, {job: jobDto}).pipe(
            map(result => new JobModel().fromDto(result))
        );
    }

    edit(job: JobModel): Observable<JobModel> {
        const jobDto = job.toDto();

        return this.http.patch<IJobDto>(environment.API_URL + SubUrlEnum.JOB_UPDATE + job.id, {job: jobDto}).pipe(
            map(result => new JobModel().fromDto(result))
        );
    }

    getJobById(id: number): Observable<JobModel> {
        return this.http.get<IJobDto>(environment.API_URL + SubUrlEnum.JOB_GET + id).pipe(
            map(result => {
                return new JobModel().fromDto(result);
            })
        );
    }

    getAllJobs(): Observable<JobModel[]> {
        return this.http.get<IJobsDto>(environment.API_URL + SubUrlEnum.JOB_GET).pipe(
            map((res: IJobsDto) => {
                if (res) {
                    return res.jobs.map(job => new JobModel().fromDto(job));
                }
                return null;
            })
        );
    }

    deleteJob(id: number): Observable<void> {
        return this.http.delete<void>(environment.API_URL + SubUrlEnum.JOB_DELETE + id);
    }

}
