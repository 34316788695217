import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ResetErrors } from '../../../shared/store/actions/error.actions';
import { ErrorsState } from './../../../shared/store/state/errors.state';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    @Select(ErrorsState.getErrors) errors$: Observable<string[]>;

    constructor(private store: Store) {}

    ngOnInit(): void {
        // setTimeout(() => {
        //     this.store.dispatch(new SetErrors(['test error']));
        //     this.store.dispatch(new SetErrors(['test error2']));
        // }, 2000);
    }

    public onClick(): void {
        this.store.dispatch(new ResetErrors());
    }
}
