import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetInfoMsg, SetInfoMsg } from '../actions/alert.actions';
import { AlertTypesEnum } from './../../enums/alert-types.enum';

export interface AlertStateModel {
    infoMsg: string;
    type: string;
}

@State<AlertStateModel>({
    name: 'Alert',
    defaults: {
        infoMsg: '',
        type: AlertTypesEnum.SUCCESS
    }
})
@Injectable()
export class AlertState {
    constructor() { }

    private infoTimeout: ReturnType<typeof setTimeout>;
    private timeOut = 4000;

    @Selector()
    static getInfoMsg(state: AlertStateModel): string {
        return state.infoMsg;
    }

    @Selector()
    static getType(state: AlertStateModel): string {
        return state.type;
    }

    @Action(SetInfoMsg)
    setInfoMsg(ctx: StateContext<AlertStateModel>, payload: SetInfoMsg) {
        ctx.patchState({
            infoMsg: payload.msg,
            type: payload.type
        });

        if (this.infoTimeout) {
            clearTimeout(this.infoTimeout);
        }

        this.infoTimeout = setTimeout(() => {
            ctx.dispatch(new ResetInfoMsg());
        }, this.timeOut);
    }

    @Action(ResetInfoMsg)
    resetInfoMsg(ctx: StateContext<AlertStateModel>) {
        ctx.patchState({
            infoMsg: '',
            type: AlertTypesEnum.SUCCESS
        });
    }
}
