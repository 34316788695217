import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './../shared/guards/auth.guard';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () => import('../views/auth/auth.module').then(m => m.AuthModule),
        data: { removeNavigation: true }
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admins',
        loadChildren: () => import('../views/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'sites',
        loadChildren: () => import('../views/sites/sites.module').then(m => m.SitesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'activities',
        loadChildren: () => import('../views/activities/activities.module').then(m => m.ActivitiesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'jobs',
        loadChildren: () => import('../views/jobs/jobs.module').then(m => m.JobsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'preventions',
        loadChildren: () => import('../views/preventions/preventions.module').then(m => m.PreventionsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'intervenants',
        loadChildren: () => import('../views/intervenants/intervenants.module').then(m => m.IntervenantsModule),
        canActivate: [AuthGuard]
    },
    { path: '**', redirectTo: '/dashboard' },
];


@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class AppRoutingModule { }
