import { IActivity } from 'src/views/activities/DTO/activity.interface';
import { IJobDto } from 'src/views/jobs/dtos/job-dto.interface';
import { IOfferDto } from 'src/views/sites/interfaces/offer.interface';

import { ISite } from './../../views/sites/dtos/ISite.interface';
import { SiteManHoursEnum } from './../../views/sites/enums/site-man-hours.enum';

const mockHospitalMapUrl = 'https://www.google.com/maps/d/thumbnail?mid=1ljsMAQTlUXnFyqwOkM2O_GX1ozo';
const mockHomepage = 'https://www.digitalvidya.com/wp-content/uploads/2019/03/Homepage-Design.jpg';
const mockImplantation = 'https://cdn.vivalia.be/sites/default/files/files/avant_projet_-_plan_mobilite_futur_chr.png';
const mockLocation = 'https://nirvan66.github.io/img/geoguessrai/location.jpg';

export const mockSites: ISite[] = [
    {
        id: 1,
        name: 'Chantier de test',
        work_type: 'Ceci est pour tester',
        site_type_id: 2,
        site_category_id: 2,
        start_date: '2020-11-17',
        end_date: null,
        area: false,
        planned_visits: 2,
        big_estimated_budget: true,
        long_estimated_duration: true,
        lot_simultaneous_workers: true,
        increased_risks: null,
        men_hours: SiteManHoursEnum.MORE500,
        more_than_3_enterprise: false,
        updated_at: '2020-10-01T12:06:42.468Z',
        work_list: ['first work', 'second work'],
        adress: {
            postcode: 4040,
            city: 'Herstal',
            street: 'Rue jean michel courard',
            number: '31',
        },
        participants_sites_attributes: [],
        aggravated_risks: [],
        hopital_adress: {
            postcode: 4040,
            city: 'Herstal',
            street: 'Rue jean michel courard',
            number: '31',
            name: 'CHR Liege',
        },
        implantation_image: mockImplantation,
        location_image: mockLocation,
        homepage_image: mockHomepage,
        hopital_map_image: mockHospitalMapUrl,
    },
    {
        id: 3,
        name: 'Chantier Construction Test',
        work_type: 'Gros oeuvre',
        site_type_id: 1,
        site_category_id: 2,
        start_date: '2021-03-01',
        end_date: '2021-03-12',
        area: false,
        planned_visits: 6,
        big_estimated_budget: true,
        long_estimated_duration: null,
        lot_simultaneous_workers: null,
        increased_risks: null,
        men_hours: SiteManHoursEnum.MORE5000,
        more_than_3_enterprise: true,
        updated_at: '2021-03-12T14:50:18.731Z',
        work_list: null,
        adress: {
            postcode: 4030,
            city: 'Grivegnée',
            street: 'Avenue de la cokerie',
            number: '21',
        },
        hopital_adress: {
            postcode: 4040,
            city: 'Herstal',
            street: 'Rue jean michel sdf',
            number: '32',
            name: 'CHR Huy',
        },
        implantation_image: mockImplantation,
        location_image: mockLocation,
        homepage_image: mockHomepage,
        hopital_map_image: mockHospitalMapUrl,
        participants_sites_attributes: [],
        aggravated_risks: [],
    },
    {
        id: 4,
        name: 'Chantier de test',
        work_type: 'Ceci est pour tester',
        site_type_id: 3,
        site_category_id: 1,
        start_date: '2020-11-17',
        end_date: null,
        area: true,
        planned_visits: 2,
        big_estimated_budget: true,
        long_estimated_duration: null,
        lot_simultaneous_workers: null,
        increased_risks: true,
        men_hours: SiteManHoursEnum.LESS500,
        more_than_3_enterprise: false,
        updated_at: '2021-03-08T13:38:03.257Z',
        work_list: null,
        adress: {
            postcode: 4040,
            city: 'Herstal',
            street: 'Rue jean michel courard',
            number: '31',
        },
        participants_sites_attributes: [
            {
                id: 2,
                participant_id: 4,
                role_id: 1,
            },
            {
                id: 3,
                participant_id: 4,
                role_id: 2,
            },
            {
                id: 4,
                participant_id: 4,
                role_id: 3,
            },
            {
                id: 5,
                participant_id: 5,
                role_id: 4,
            },
            {
                id: 6,
                participant_id: 5,
                role_id: 5,
            },
            {
                id: 6,
                participant_id: 5,
                role_id: 9,
            },
        ],
        hopital_adress: {
            postcode: 4040,
            city: 'Herstal',
            street: 'Rue jean michel sdf',
            number: '32',
            name: 'test',
        },
        implantation_image: mockImplantation,
        location_image: mockLocation,
        homepage_image: mockHomepage,
        hopital_map_image: mockHospitalMapUrl,
        aggravated_risks: [
            {
                id: 1,
                name: 'Risque d’ensevelissement, d’enlisement ou de chute de hauteur',
            },
            {
                id: 2,
                name: 'Exposition à des agents chimiques ou biologiques',
            },
            {
                id: 3,
                name: 'Exposition à des radiations ionisantes',
            },
        ],
    },
];

export const mockOffers: IOfferDto[] = [
    {
        id: 1,
        site_id: 4,
        title: 'first offer',
        file_url: 'https://ptgmedia.pearsoncmg.com/images/9780321815385/samplepages/0321815386.pdf',
        evaluation: 4,
    },
    {
        id: 2,
        site_id: 3,
        title: 'second offer',
        file_url: 'https://ptgmedia.pearsoncmg.com/images/9780321815385/samplepages/0321815386.pdf',
        evaluation: 2,
    },
    {
        id: 3,
        site_id: 2,
        title: 'third offer',
        file_url: 'https://ptgmedia.pearsoncmg.com/images/9780321815385/samplepages/0321815386.pdf',
        evaluation: 5,
    },
    {
        id: 4,
        title: 'fourth offer',
        site_id: 1,
        file_url: 'https://ptgmedia.pearsoncmg.com/images/9780321815385/samplepages/0321815386.pdf',
        evaluation: 1,
    },
];

export const mockActivities: IActivity[] = [
    {
        id: 1,
        name: 'Première activité',
        risk_ids: [64, 65],
    },
    {
        id: 5,
        name: 'seconde activité',
        risk_ids: [64, 65],
    },
    {
        id: 21,
        name: 'troisième activité',
        risk_ids: [64, 65],
    },
    {
        id: 22,
        name: 'nouvelle activité',
        risk_ids: [64, 65],
    },
];

export const mockJobs: IJobDto[] = [
    {
        id: 1,
        name: 'Maçons',
        activity_ids: [22, 21],
    },
    {
        id: 2,
        name: 'Architecte',
        activity_ids: [5],
    },
    {
        id: 12,
        name: 'Plafonneur',
        activity_ids: [1],
    },
    {
        id: 13,
        name: 'Chauffagiste',
        activity_ids: [5],
    },
];
