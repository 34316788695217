import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdName, IdNameValue } from 'src/shared/interfaces/global.interface';

import { environment } from '../../../environments/environment';
import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';
import { IRisk, IRisks } from '../interfaces/risk.interface';

@Injectable({
    providedIn: 'root'
})
export class RisksService {

    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    getRiskById(riskId: string): Observable<IRisk> {
        return this.http.get<IRisk>(environment.API_URL + SubUrlEnum.RISK_DETAILS + riskId);
    }

    // searchRisks(searchString: string): Observable<RiskModel[]> {
    //     return this.http.get<RiskModel[]>(environment.API_URL + SubUrlEnum.RISKS_SEARCH + searchString)
    //         .pipe(map((data: any) => data.risks));
    // }

    updateRisk(risk: IRisk) {
        return this.http.put<IRisk>(environment.API_URL + SubUrlEnum.RISK_UPDATE + risk.id, risk, this.httpOptions);
    }

    deleteRisk(riskId: string) {
        return this.http.delete<IRisk>(environment.API_URL + SubUrlEnum.RISK_DELETE + riskId);
    }

    getRisks(): Observable<IRisk[]> {
        return this.http
            .get<IRisks>(environment.API_URL + SubUrlEnum.RISKS_LIST)
            .pipe(map((data: any) => data.risks));
    }

    getIncreasedRisks(): Observable<IdName[]> {
        return this.http
            .get<IdName[]>(environment.API_URL + SubUrlEnum.AGGRAVATED_RISKS)
            .pipe(map((data: any) => data.aggravated_risks));
    }

    getRiskProbalities(): Observable<IdNameValue[]> {
        return this.http
            .get<IdNameValue[]>(
                environment.API_URL + SubUrlEnum.ANALYSES_PROBALITIES
            )
            .pipe(map((data: any) => data.probabilities));
    }

    getRiskFrequencies(): Observable<IdNameValue[]> {
        return this.http
            .get<IdNameValue[]>(
                environment.API_URL + SubUrlEnum.ANALYSES_FREQUENCIES
            )
            .pipe(map((data: any) => data.frequencies));
    }

    getRiskGravities(): Observable<IdNameValue[]> {
        return this.http
            .get<IdNameValue[]>(
                environment.API_URL + SubUrlEnum.ANALYSES_GRAVITIES
            )
            .pipe(map((data: any) => data.gravities));
    }

    getRiskManagement(): Observable<IdNameValue[]> {
        return this.http
            .get<IdNameValue[]>(
                environment.API_URL + SubUrlEnum.ANALYSES_RISK_MANAGEMENT
            )
            .pipe(map((data: any) => data.risk_managements));
    }
}
