import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ToggleHide, ToggleShow } from './../store/actions/loader.actions';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(private store: Store) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.store.dispatch(new ToggleShow());
        return next.handle(request).pipe(
            tap((response) => {
                if (response instanceof HttpResponse) {
                    this.store.dispatch(new ToggleHide());
                }
            }),
            catchError(err => {
                this.store.dispatch(new ToggleHide());
                return throwError(err);
            })
        );
    }
}

