import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/abstracts/base-component.abstract';

@Component({
    selector: 'app-list-search',
    templateUrl: './list-search.component.html',
    styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent extends BaseComponent implements OnInit {
    @Output() filterEvent = new EventEmitter<string>();

    public filteredData$: Observable<any[]>;

    private searchTerms: Subject<string>;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.searchTerms = new Subject<string>();

        this.uns = this.searchTerms.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe((searchTerm: string) => {
            this.filterEvent.emit(searchTerm);
        });
    }

    public resetList(searchInput: HTMLInputElement): void {
        this.searchTerms.next('');

        searchInput.value = '';
    }

    public search(term: string): void {
        this.searchTerms.next(term.trim().toLowerCase());
    }
}
