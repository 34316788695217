import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Directive()
export abstract class FormValidatorComponent implements Validator {

    public validatorOnChange = () => { };

    validate(control: AbstractControl): ValidationErrors {
        return null;
    }
    registerOnValidatorChange?(fn: () => void): void {
        this.validatorOnChange = fn;
    }
}
