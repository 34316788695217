import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IAnalysisIn } from 'src/views/sites/interfaces/pss.interface';
import { RisksService } from 'src/views/sites/services/risks.service';
import { SiteService } from 'src/views/sites/services/sites.service';

import { IRisk } from '../../../views/sites/interfaces/risk.interface';
import { AnalyseService } from '../../../views/sites/services/analyse.service';
import { IdName, IdNameValue } from './../../interfaces/global.interface';
import {
    CreateRiskAnalyse,
    GetAggravatedRisks,
    GetAllRiskAnalysis,
    GetAllSiteRisks,
    GetRiskFrequency,
    GetRiskGravity,
    GetRiskManagement,
    GetRiskProbability,
    ResetRiskAnalysis,
    UpdateRisk,
    UpdateRiskAnalyse,
} from './../actions/site.action';

export class SiteRisksStateModel {
    public risks: IRisk[];
    public frequencies: IdNameValue[];
    public gravities: IdNameValue[];
    public probalities: IdNameValue[];
    public riskManagement: IdNameValue[];
    public isListFetched: boolean;
    public analysisList: IAnalysisIn[];
    public aggravatedRisks: IdName[];
}

@State<SiteRisksStateModel>({
    name: 'risks',
    defaults: {
        risks: [],
        frequencies: [],
        gravities: [],
        probalities: [],
        riskManagement: [],
        isListFetched: false,
        analysisList: [],
        aggravatedRisks: [],
    },
})
@Injectable()
export class SiteRisksState {
    constructor(private siteService: SiteService, private analyseService: AnalyseService, private risksService: RisksService) {}

    @Selector()
    public static getAllSiteRisks(state: SiteRisksStateModel): IRisk[] {
        return state.risks;
    }

    @Selector()
    public static getAllProbalities(state: SiteRisksStateModel): IdNameValue[] {
        return state.probalities;
    }

    @Selector()
    public static getAllFrequencies(state: SiteRisksStateModel): IdNameValue[] {
        return state.frequencies;
    }

    @Selector()
    public static getAllGravities(state: SiteRisksStateModel): IdNameValue[] {
        return state.gravities;
    }

    @Selector()
    public static getAllRiskManagement(state: SiteRisksStateModel): IdNameValue[] {
        return state.riskManagement;
    }

    @Selector()
    public static getAllAnalysis(state: SiteRisksStateModel): IAnalysisIn[] {
        return state.analysisList;
    }

    @Selector()
    public static getAllAggravatedRisks(state: SiteRisksStateModel): IdName[] {
        return state.aggravatedRisks;
    }

    @Action(GetAllSiteRisks)
    public getAllRisks(ctx: StateContext<SiteRisksStateModel>): Observable<any> {
        const state = ctx.getState();
        if (state.risks.length > 0) {
            return;
        }
        return this.risksService.getRisks().pipe(
            tap((result) => {
                ctx.patchState({
                    risks: result,
                });
            })
        );
    }

    @Action(GetRiskProbability)
    public getRiskProbability(ctx: StateContext<SiteRisksStateModel>): Observable<any> {
        const state = ctx.getState();
        if (state.probalities.length > 0) {
            return;
        }
        return this.risksService.getRiskProbalities().pipe(
            tap((result) => {
                ctx.patchState({
                    probalities: result,
                });
            })
        );
    }

    @Action(GetRiskFrequency)
    public getRiskFrequency(ctx: StateContext<SiteRisksStateModel>): Observable<any> {
        const state = ctx.getState();
        if (state.frequencies.length > 0) {
            return;
        }
        return this.risksService.getRiskFrequencies().pipe(
            tap((result) => {
                ctx.patchState({
                    frequencies: result,
                });
            })
        );
    }

    @Action(GetRiskGravity)
    public getRiskGravity(ctx: StateContext<SiteRisksStateModel>): Observable<any> {
        const state = ctx.getState();
        if (state.gravities.length > 0) {
            return;
        }
        return this.risksService.getRiskGravities().pipe(
            tap((result) => {
                ctx.patchState({
                    gravities: result,
                });
            })
        );
    }

    @Action(GetRiskManagement)
    public getRiskManagement(ctx: StateContext<SiteRisksStateModel>): Observable<any> {
        const state = ctx.getState();
        if (state.riskManagement.length > 0) {
            return;
        }
        return this.risksService.getRiskManagement().pipe(
            tap((result) => {
                ctx.patchState({
                    riskManagement: result,
                });
            })
        );
    }

    @Action(CreateRiskAnalyse)
    public createRiskAnalyse(ctx: StateContext<SiteRisksStateModel>, action: CreateRiskAnalyse): Observable<any> {
        return this.analyseService.createAnalyse(action.payload.analyse).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        analysisList: insertItem<IAnalysisIn>(result),
                    })
                );
            })
        );
    }

    @Action(UpdateRiskAnalyse)
    public updateRiskAnalyse(ctx: StateContext<SiteRisksStateModel>, action: UpdateRiskAnalyse): Observable<any> {
        return this.analyseService.updateAnalyse(action.payload.analyse, action.payload.id).pipe(
            tap((result: IAnalysisIn) => {
                ctx.setState(
                    patch({
                        analysisList: updateItem<IAnalysisIn>((a) => a.id === result.id, result),
                    })
                );
            })
        );
    }

    @Action(UpdateRisk)
    public updateRisk(ctx: StateContext<SiteRisksStateModel>, action: UpdateRisk): void {
        action.risks.forEach((risk) => {
            ctx.setState(
                patch({
                    risks: updateItem<IRisk>((r) => r.id === risk.id, {
                        id: risk.id,
                        complete_risk: risk.complete_risk,
                        simplified_risk: risk.simplified_risk,
                        measure_ids: risk.measure_ids,
                    }),
                })
            );
        });
    }

    @Action(GetAllRiskAnalysis)
    public getRiskAnalysisBySiteId(ctx: StateContext<SiteRisksStateModel>, action: GetAllRiskAnalysis): Observable<any> {
        return this.analyseService.getAnalysisBySiteId(action.payload.siteId).pipe(
            tap((result: IAnalysisIn[]) => {
                ctx.patchState({
                    analysisList: result,
                });
            })
        );
    }

    @Action(ResetRiskAnalysis)
    public resetRiskAnalysis(ctx: StateContext<SiteRisksStateModel>): void {
        ctx.patchState({
            analysisList: [],
        });
    }

    @Action(GetAggravatedRisks)
    public getAggravatedRisks(ctx: StateContext<SiteRisksStateModel>): Observable<any> {
        const state = ctx.getState();
        if (state.aggravatedRisks.length > 0) {
            return;
        }
        return this.risksService.getIncreasedRisks().pipe(
            tap((result: IdName[]) => {
                ctx.patchState({
                    aggravatedRisks: result,
                });
            })
        );
    }
}
