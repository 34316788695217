export enum SubUrlEnum {
    // AUTH
    LOGIN = 'users/sign_in?',
    LOGOUT = 'users/sign_out',
    REFRESH_TOKEN = 'users/tokens',

    // USERS
    GET_ALL_USERS = 'users/',
    GET_USER = 'users/',
    CREATE_USER = 'users/',
    UPDATE_USER = 'users/',
    DELETE_USER = 'users/',

    // ROLES
    ROLES_LIST = 'roles',

    // RISKS
    RISKS_LIST = 'risks',
    RISK_DETAILS = 'risks/',
    RISK_ADD = 'risks',
    RISK_UPDATE = 'risks/',
    RISK_DELETE = 'risks/',
    RISKS_SEARCH = 'risks?search=',

    // THEMES
    THEMES_LIST = 'themes',
    THEME_DETAILS = 'themes/',
    THEME_ADD = 'themes',
    THEME_UPDATE = 'themes/',
    THEME_DELETE = 'themes/',

    // SITES
    SITES_LIST = 'sites',
    SITE_DETAILS = 'sites/',
    SITE_ADD = 'sites/',
    SITE_UPDATE = 'sites/',
    SITE_DELETE = 'sites/',
    SECURITY_PLAN_DETAILS = 'sites/{id}/security_plans/',
    SECURITY_PLAN_ADD = 'sites/{id}/security_plans',
    SECURITY_PLAN_UPDATE = 'sites/{id}/security_plans/',
    SECURITY_PLAN_DELETE = 'sites/{id}/security_plans/',
    VISITS_BY_SITE = 'sites/{id}/visits',
    VISIT_RISK = 'visits/{id}/risks',
    CATEGORIES = 'sites/sites_categories',
    TYPES = 'sites/sites_types',
    SITE_CONVENTIONS = 'conventions?site_id=',
    SITE_CONVENTION_GENERATE = 'conventions',
    SITE_CONVENTION_MAIL = 'conventions/{id}/mail',
    SITE_PROGRESS = 'sites/{id}/progress',

    // DIU (document)
    SITE_CONVENTION_SIGNED = 'conventions/{id}',
    SITE_DIUS = 'documents?site_id=',
    SITE_DIU_UPLOAD = 'documents',
    SITE_DIU_DELETE = 'documents/',
    DOCUMENT_TYPES = 'document_types',
    DOCUMENT = 'documents/{id}',
    DOCUMENTS = 'sites/{id}/documents',
    DOCUMENT_UPDATE = 'documents/{id}',

    // OFFERS
    OFFERS = 'offers/',

    // AGGRAVATED RISKS
    AGGRAVATED_RISKS = 'aggravated_risks/',

    // INTERVENANTS
    INTERVENANTS_LIST = 'participants/',
    INTERVENANTS_DETAILS = 'participants/',
    INTERVENANTS_ADD = 'participants/',
    INTERVENANTS_UPDATE = 'participants/',
    INTERVENANTS_DELETE = 'participants/',

    // ACTIVITIES
    ACTIVITY_ADD = 'activities/',
    ACTIVITY_GET = 'activities/',
    ACTIVITY_UPDATE = 'activities/',
    ACTIVITY_DELETE = 'activities/',
    ACTIVITY_LIST = 'activities/',

    // JOBS
    JOB_ADD = 'jobs/',
    JOB_GET = 'jobs/',
    JOB_UPDATE = 'jobs/',
    JOB_DELETE = 'jobs/',
    JOB_LIST = 'jobs/',

    // STATS
    STATS_GET = 'stats/',

    // Measures(Preventions)
    MEASURES = 'measures',
    MEASURE = 'measures/{id}',

    // VISITING REPORT
    VISITING_REPORT_ADD = 'visits/',
    VISITING_REPORT_GET = 'visits/',
    VISITING_REPORT_UPDATE = 'visits/',
    VISITING_REPORT_DELETE = 'visits/',
    VISITING_REPORT_LIST = 'visits?',

    // ANALYSES
    ANALYSE_ADD = 'analyses',
    ANALYSES_PROBALITIES = 'analyses/probabilities',
    ANALYSES_FREQUENCIES = 'analyses/frequencies',
    ANALYSES_GRAVITIES = 'analyses/gravities',
    ANALYSES_RISK_MANAGEMENT = 'analyses/risk_managements',
}
