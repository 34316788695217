import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthService } from './../../../views/auth/services/auth.service';
import { Login, Logout, RefreshSession, ResetErrors } from './../actions/auth.actions';


export interface AuthStateModel {
    email: string;
    accessToken: string;
    refreshToken: string;
    errors: string[];
}
@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        email: null,
        accessToken: null,
        refreshToken: null,
        errors: []
    }
})

@Injectable()
export class AuthState {
    @Selector()
    static accessToken(state: AuthStateModel) {
        return state.accessToken;
    }

    @Selector()
    static refreshToken(state: AuthStateModel) {
        return state.refreshToken;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.accessToken;
    }

    @Selector()
    static getAccessToken(state: AuthStateModel) {
        return state.accessToken;
    }

    @Selector()
    static getErrorAuth(state: AuthStateModel) {
        return state.errors;
    }

    ngxsAfterBootstrap(ctx?: StateContext<AuthStateModel>): void {
        ctx.dispatch(new RefreshSession());
    }

    constructor(private authService: AuthService) { }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this.authService.login(action.payload.email, action.payload.password).pipe(
            tap((result: { accessToken: string, refreshToken: string }) => {
                ctx.patchState({
                    accessToken: result.accessToken,
                    refreshToken: result.refreshToken,
                    email: action.payload.email,
                });
            }),
            catchError(err => {
                if (err.status === 422) {
                    ctx.patchState({
                        errors: ['Email ou mot de passe incorrect']
                    });
                } else {
                    ctx.patchState({
                        errors: err.error.errors || [err.error.error]
                    });
                }

                return of(err);
            })
        );
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        return this.authService.logout(state.accessToken).pipe(
            tap(() => {
                ctx.patchState({
                    accessToken: null,
                    refreshToken: null,
                });
            }),
            catchError(err => {
                ctx.patchState({
                    accessToken: null,
                    refreshToken: null,
                });
                return of(err);
            })
        );
    }

    @Action(RefreshSession)
    refreshTokens(ctx: StateContext<AuthStateModel>) {
        const state = ctx.getState();
        if ((!state.refreshToken && !state.accessToken)) {
            return;
        }
        return this.authService.refreshToken(state.accessToken, state.refreshToken).pipe(
            tap(({ accessToken, refreshToken }) => {
                ctx.patchState({
                    accessToken,
                    refreshToken,
                });
            }),
            catchError(err => {
                ctx.patchState({
                    accessToken: null,
                    refreshToken: null,
                });
                return of(err);
            })
        );
    }

    @Action(ResetErrors)
    resetErrors(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({
            errors: []
        });
    }

}
