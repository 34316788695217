import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlComponent } from 'src/shared/abstracts/form-control.abstract';

@Component({
    selector: 'app-rating-input',
    templateUrl: './rating-input.component.html',
    styleUrls: ['./rating-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => RatingInputComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => RatingInputComponent),
        }
    ]
})
export class RatingInputComponent extends FormControlComponent {
    @Input() ratingScale = 5;

    public selectedRating: number;
    public localRating: number;
    public ratingIterable = Array(this.ratingScale);

    constructor() {
        super();
    }

    public writeValue(value: number): void {
        this.selectedRating = value;
        this.localRating = value;
    }

    public onClick(value: number) {
        this.markAsTouched();
        if (!this.disabled) {
            this.selectedRating = value;
            this.localRating = value;

            this.onChange(this.selectedRating);
        }
    }

    public updateRating(value: number): void {
        this.localRating = value;
    }

    public resetRating() {
        if (this.selectedRating) {
            this.localRating = this.selectedRating;
        }
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
