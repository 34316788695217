<div class="sidebar">
    <a class="nav-button" routerLink="/dashboard" [routerLinkActive]="'-active'">
        <span class="icon icon-meter"></span>
        <p class="nav-link">Dashboard</p>
    </a>
    <a class="nav-button" routerLink="/admins" [routerLinkActive]="'-active'">
        <span class="icon icon-users"></span>
        <p class="nav-link">Administrateurs</p>
    </a>
    <a class="nav-button" routerLink="/sites" [routerLinkActive]="'-active'">
        <span class="icon icon-hammer"></span>
        <p class="nav-link">Chantiers</p>
    </a>
    <div class="dropdown">
        <a class="nav-button" [routerLinkActive]="'-active'">
            <span class="icon icon-book"></span>
            <p class="nav-link dropbtn">Catalogue</p>
        </a>
        <div class="dropdown-content">
            <a [routerLink]="['/activities']" href="#" [routerLinkActive]="'-active'">Activités</a>
            <a [routerLink]="['/intervenants']" href="#" [routerLinkActive]="'-active'">Intervenants</a>
            <a [routerLink]="['/jobs']" href="#" [routerLinkActive]="'-active'">Métiers</a>
            <a [routerLink]="['/preventions']" href="#" [routerLinkActive]="'-active'">Mesures de préventions</a>
        </div>
    </div>
    <a class="nav-button" (click)="logout()">
        <span class="icon icon-exit"></span>
        <p class="nav-link">Déconnexion</p>
    </a>
</div>
