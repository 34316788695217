import { IActivity } from 'src/views/activities/DTO/activity.interface';

import { IActivityDtoOut } from './../../../views/activities/DTO/activity.interface';

export class CreateActivity {
    static readonly type = '[Activity] Create';
    constructor(public activity: IActivityDtoOut) { }
}

export class EditActivity {
    static readonly type = '[Activity] Edit';
    constructor(public updatedActivity: IActivityDtoOut, public oldActivity: IActivity) { }
}

export class GetActivityById {
    static readonly type = '[Activity] Get activity by id';
    constructor(public id: number) { }
}

export class GetAllActivities {
    static readonly type = '[Activity] Get list';
    constructor() { }
}

export class DeleteActivity {
    static readonly type = '[Activity] Delete';
    constructor(public id: number) { }
}

export class ResetActivity {
    static readonly type = '[Activity] Reset';
}
