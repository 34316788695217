import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, iif, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SiteOfferService } from '../../../views/sites/services/offers.service';
import { IOffer } from './../../../views/sites/interfaces/offer.interface';
import { CreateOffer, DeleteOffer, GetAllOffers, UpdateOffer } from './../actions/offers.actions';

export interface OffersStateModel {
    offers: IOffer[];
    offer: IOffer;
    currentSiteId: number;
    isListFetched: boolean;
}

@State<OffersStateModel>({
    name: 'offers',
    defaults: {
        offers: [],
        offer: null,
        currentSiteId: null,
        isListFetched: false,
    },
})
@Injectable()
export class OffersState {
    @Selector()
    public static getAllOffers(state: OffersStateModel): IOffer[] {
        return state.offers;
    }

    constructor(private offerService: SiteOfferService) {}

    @Action(GetAllOffers)
    public getAllOffers(ctx: StateContext<OffersStateModel>, payload: GetAllOffers): Observable<any> {
        return this.offerService.getOffers(payload.siteId).pipe(
            tap((result) => {
                ctx.patchState({
                    offers: result,
                    currentSiteId: payload.siteId,
                    isListFetched: true,
                });
            })
        );
    }

    @Action(CreateOffer)
    public createOffer(ctx: StateContext<OffersStateModel>, action: CreateOffer): Observable<any> {
        return this.offerService.createOffer(action.offer).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        offers: append<IOffer>([result]),
                        offer: result,
                    })
                );
            })
        );
    }

    @Action(UpdateOffer)
    public updateOffer(ctx: StateContext<OffersStateModel>, action: UpdateOffer): Observable<any> {
        return this.offerService.updateOffer(action.offer).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        offers: updateItem<IOffer>((offer) => offer.id === result.id, result),
                        offer: result,
                    })
                );
            })
        );
    }

    @Action(DeleteOffer)
    public deleteOffer(ctx: StateContext<OffersStateModel>, action: DeleteOffer): Observable<any> {
        const state = ctx.getState();

        return this.offerService.deleteOffer(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        offers: removeItem<IOffer>((offer) => offer.id === action.id),
                        offer: iif<IOffer>(state.offer && state.offer.id === action.id, null, state.offer),
                    })
                );
            })
        );
    }
}
