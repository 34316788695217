import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ToggleHide, ToggleShow } from './../actions/loader.actions';

export interface LoaderStateModel {
    loaderCount: number;
    isLoading: boolean;
}

@State<LoaderStateModel>({
    name: 'loader',
    defaults: {
        loaderCount: 0,
        isLoading: false
    }
})
@Injectable()
export class LoaderState {
    @Selector()
    static isLoading(state: LoaderStateModel): boolean {
        return state.isLoading;
    }

    @Action(ToggleShow)
    toggleShow(ctx: StateContext<LoaderStateModel>) {
        const state = ctx.getState();

        ctx.patchState({
            isLoading: true,
            loaderCount: state.loaderCount + 1
        });
    }

    @Action(ToggleHide)
    toggleHide(ctx: StateContext<LoaderStateModel>) {
        const state = ctx.getState();

        const remainingLoaders = state.loaderCount - 1 > 0 ? state.loaderCount - 1 : 0;

        ctx.patchState({
            isLoading: remainingLoaders > 0,
            loaderCount: remainingLoaders
        });
    }
}
