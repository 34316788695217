import { HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

import { mockJobs } from './mock-data';

export const mockJobsRes = (request: HttpRequest<any>) => {
    const data = mockJobs;

    // Check if there is an id at the end of the URL
    const idMatch = request.url.match(/(?<id>\d+)$/);

    if (request.method === 'GET') {
        let res;

        if (idMatch) {
            const id = parseInt(idMatch.groups?.id, 10) || null;
            const jobToReturn = data.find(job => job.id === id) || null;

            res = {
                status: 200,
                body: jobToReturn
            };
        } else {
            res = {
                status: 200,
                body: { jobs: data }
            };
        }

        return of(new HttpResponse(res));
    }

    if (request.method === 'POST') {
        const newId = Math.floor(Math.random() * 100);

        const newJob = { ...request.body, id: newId };

        const res = {
            status: 200,
            body: newJob
        };

        return of(new HttpResponse(res));
    }

    if (request.method === 'PATCH') {
        const updatedActivity = request.body;

        const res = {
            status: 200,
            body: updatedActivity
        };

        return of(new HttpResponse(res));
    }

    if (request.method === 'DELETE') {
        const res = {
            status: 204
        };

        return of(new HttpResponse(res));
    }
    return of(new HttpResponse());
};
