import { FileInputComponent } from './file-input/file-input.component';
import { FilterableDropdownComponent } from './filterable-dropdown/filterable-dropdown.component';
import { ImagesFormComponent } from './images-form/images-form.component';
import { InputImageComponent } from './input-image/input-image.component';
import { InputTextComponent } from './input-text/input-text.component';
import { ListSearchComponent } from './list-search/list-search.component';
import { MainAddButtonComponent } from './main-add-button/main-add-button.component';
import { ModalValidationComponent } from './modal-validation/modal-validation.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RatingInputComponent } from './rating-input/rating-input.component';

export const componentsShared: any[] = [
    ModalValidationComponent,
    MainAddButtonComponent,
    ListSearchComponent,
    FilterableDropdownComponent,
    InputTextComponent,
    ProgressBarComponent,
    RatingInputComponent,
    ImagesFormComponent,
    FileInputComponent,
    InputImageComponent,
];

export * from './modal-validation/modal-validation.component';
export * from './main-add-button/main-add-button.component';
export * from './list-search/list-search.component';
export * from './filterable-dropdown/filterable-dropdown.component';
export * from './input-text/input-text.component';
export * from './progress-bar/progress-bar.component';
export * from './rating-input/rating-input.component';
export * from './images-form/images-form.component';
export * from './file-input/file-input.component';
export * from './input-image/input-image.component';
