import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';
import { ConventionType } from '../enums/site-convention-type.enum';
import { IConvention, IConventionDtoIn, IConventionsDtoIn } from '../interfaces/convention.interface';

@Injectable({
    providedIn: 'root',
})
export class SiteConventionService {
    constructor(private http: HttpClient) {}

    getConventions(siteId: string): Observable<IConvention[]> {
        return this.http.get<IConventionsDtoIn>(environment.API_URL + SubUrlEnum.SITE_CONVENTIONS + siteId).pipe(
            map((response) => {
                const conventions = response.conventions.map((c) => this.conventionBackToFront(c));
                return conventions;
            })
        );
    }

    public generateConvention(type: ConventionType, siteId: string): Observable<IConvention> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            }),
        };
        const formData = new FormData();
        formData.append('convention[site_id]', siteId);
        formData.append('convention[phase]', type.toString());

        return this.http
            .post<IConventionDtoIn>(environment.API_URL + SubUrlEnum.SITE_CONVENTION_GENERATE, formData, httpOptions)
            .pipe(map((response) => this.conventionBackToFront(response)));
    }

    public sendConventionByMail(conventionId: number): Observable<boolean> {
        return this.http
            .post<any>(environment.API_URL + SubUrlEnum.SITE_CONVENTION_MAIL.replace('{id}', conventionId.toString()), {})
            .pipe(map((_) => true));
    }

    public sendSignedConvention(siteId: number, conventionId: number, conventionSigned: File): Observable<IConvention> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            }),
        };
        const formData = new FormData();
        formData.append('convention[pdf_signed_file]', conventionSigned);
        formData.append('convention[site_id]', siteId.toString());

        return this.http
            .patch<any>(
                environment.API_URL + SubUrlEnum.SITE_CONVENTION_SIGNED.replace('{id}', conventionId.toString()),
                formData,
                httpOptions
            )
            .pipe(map((result) => this.conventionBackToFront(result)));
    }

    private conventionBackToFront(response: IConventionDtoIn): IConvention {
        return {
            id: response.id,
            siteId: response.site_id,
            name: response.pdf_file_url.substring(response.pdf_file_url.lastIndexOf('/') + 1),
            phase: response.phase,
            pdfFileUrl: 'https://symbiose.dev.itw.dgnus.net:8080/' + response.pdf_file_url,
            pdfSignedUrl: response.pdf_signed_file_url
                ? 'https://symbiose.dev.itw.dgnus.net:8080/' + response.pdf_signed_file_url
                : undefined,
        };
    }
}
