import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IVisitReportImages } from 'src/views/sites/interfaces/reports.interface';

@Component({
    selector: 'app-images-form',
    templateUrl: './images-form.component.html',
    styleUrls: ['./images-form.component.scss'],
})
export class ImagesFormComponent {
    @Input() public images: IVisitReportImages[];
    @Output() public newImagesEvent = new EventEmitter<IVisitReportImages[]>();

    public imgInTheShow = ''; // url de l'image agrandie

    public removeImage(idImage: number): void {
        this.images = this.images.filter((img) => img.id !== idImage);
        this.newImagesEvent.emit(this.images);
    }

    public showImage(img: string): void {
        // si img deja agrandie, la remettre normal
        if (this.imgInTheShow === img) {
            this.imgInTheShow = '';
        }
        // sinon l'agrandir
        else {
            this.imgInTheShow = img;
        }
    }
}
