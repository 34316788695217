<div class="grid-container">
    <app-logo *ngIf="!hideNavigation" class="logo"></app-logo>
    <app-sidemenu *ngIf="!hideNavigation" class="sidemenu"></app-sidemenu>
    <app-topnav *ngIf="!hideNavigation" class="topnav"></app-topnav>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
<app-error></app-error>
<app-alert></app-alert>
<app-loader></app-loader>
