import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { IMeasure } from 'src/views/activities/DTO/measure.interface';

import { MeasuresService } from './../../../views/preventions/services/measures.service';
import {
    CreateMeasure,
    DeleteMeasure,
    EditMeasure,
    GetAllMeasures,
    GetMeasureById,
    ResetMeasures,
} from './../actions/measures.actions';

export interface MeasuresStateModel {
    measures: IMeasure[];
    measure: IMeasure;
    isListFetched: boolean;
}

@State<MeasuresStateModel>({
    name: 'measures',
    defaults: {
        measures: [],
        measure: null,
        isListFetched: false
    }
})
@Injectable()
export class MeasureState {
    @Selector()
    static getAllMeasures(state: MeasuresStateModel) {
        return state.measures;
    }

    @Selector()
    static getMeasureById(state: MeasuresStateModel) {
        return state.measure;
    }

    constructor(private measuresService: MeasuresService) { }

    @Action(GetAllMeasures)
    getAllMeasures(ctx: StateContext<MeasuresStateModel>) {
        const state = ctx.getState();

        if (state.isListFetched) {
            return;
        }

        return this.measuresService.getAllMeasures().pipe(tap(results => {
            ctx.patchState({
                measures: results,
                isListFetched: true
            });
        }));
    }

    @Action(GetMeasureById)
    getMeasureById(ctx: StateContext<MeasuresStateModel>, payload: GetMeasureById) {
        const state = ctx.getState();

        if (state.measures.length) {
            const measureToFind = state.measures.find(measure => measure.id === payload.id);

            if (measureToFind) {
                return ctx.patchState({
                    measure: measureToFind
                });
            }
        }

        return this.measuresService.getMeasureById(payload.id).pipe(tap(result => {
            ctx.patchState({
                measure: result
            });
        }));
    }

    @Action(CreateMeasure)
    createMeasure(ctx: StateContext<MeasuresStateModel>, payload: CreateMeasure) {
        return this.measuresService.createMeasure(payload.measure).pipe(tap(result => {
            ctx.setState(
                patch({
                    measures: append<IMeasure>([result]),
                    measure: result
                })
            );
        }));
    }

    @Action(EditMeasure)
    editMeasure(ctx: StateContext<MeasuresStateModel>, payload: EditMeasure) {
        return this.measuresService.updateMeasure(payload.measure).pipe(tap(result => {
            ctx.setState(
                patch({
                    measures: updateItem<IMeasure>(measure => measure.id === payload.measure.id, result),
                    measure: result
                })
            );
        }));

    }

    @Action(DeleteMeasure)
    deleteMeasure(ctx: StateContext<MeasuresStateModel>, payload: DeleteMeasure) {
        return this.measuresService.deleteMeasure(payload.id).pipe(tap(() => {
            ctx.setState(
                patch({
                    measures: removeItem<IMeasure>(measure => measure.id === payload.id),
                    measure: null
                })
            );
        }));
    }

    @Action(ResetMeasures)
    resetMeasures(ctx: StateContext<MeasuresStateModel>) {
        ctx.patchState({
            measures: [],
            measure: null,
            isListFetched: false
        });
    }
}
