import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IntervenantModel } from './../../../views/intervenants/models/intervenant.model';
import { IntervenantsService } from './../../../views/sites/services/intervenants.service';
import {
    CreateIntervenant,
    DeleteIntervenant,
    GetAllIntervenants,
    GetIntervenantById,
    UpdateIntervenant,
} from './../actions/intervenants.actions';

export class IntervenantsStateModel {
    public intervenant: IntervenantModel;
    public intervenants: IntervenantModel[];
    public isListFetched: boolean;
}

@State<IntervenantsStateModel>({
    name: 'intervenants',
    defaults: {
        intervenants: [],
        intervenant: null,
        isListFetched: false,
    },
})
@Injectable()
export class IntervenantsState {
    constructor(private intervenantsService: IntervenantsService) {}

    @Selector()
    public static getAllIntervenants(state: IntervenantsStateModel): IntervenantModel[] {
        return state.intervenants;
    }

    @Selector()
    public static getIntervenantById(state: IntervenantsStateModel): IntervenantModel {
        return state.intervenant;
    }

    @Action(GetAllIntervenants)
    public getAllIntervenants(ctx: StateContext<IntervenantsStateModel>): Observable<any> {
        return this.intervenantsService.getAllIntervenants().pipe(
            tap((result) => {
                ctx.patchState({
                    intervenants: result,
                    isListFetched: true,
                });
            })
        );
    }

    @Action(GetIntervenantById)
    public getIntervenantById(ctx: StateContext<IntervenantsStateModel>, action: GetIntervenantById): Observable<any> {
        return this.intervenantsService.getIntervenantById(action.id).pipe(
            tap((result) => {
                ctx.patchState({
                    intervenant: result,
                });
            })
        );
    }

    @Action(CreateIntervenant)
    public createIntervenant(ctx: StateContext<IntervenantsStateModel>, action: CreateIntervenant): Observable<any> {
        return this.intervenantsService.createIntervenant(action.intervenant).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        intervenants: append<IntervenantModel>([result]),
                        intervenant: result,
                    })
                );
            })
        );
    }

    @Action(UpdateIntervenant)
    public updateIntervenant(ctx: StateContext<IntervenantsStateModel>, action: UpdateIntervenant): Observable<any> {
        return this.intervenantsService.updateIntervenant(action.intervenant).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        intervenant: result,
                        intervenants: updateItem<IntervenantModel>((intervenant) => intervenant.id === result.id, result),
                    })
                );
            })
        );
    }

    @Action(DeleteIntervenant)
    public deleteIntervenant(ctx: StateContext<IntervenantsStateModel>, action: DeleteIntervenant): Observable<any> {
        return this.intervenantsService.deleteIntervenant(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        intervenants: removeItem<IntervenantModel>((intervenant) => intervenant.id === action.id),
                        intervenant: null,
                    })
                );
            })
        );
    }
}
