import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AlertTypesEnum } from 'src/shared/enums/alert-types.enum';
import { ResetInfoMsg } from 'src/shared/store/actions/alert.actions';

import { AlertState } from './../../../shared/store/state/alert.state';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    @Select(AlertState.getInfoMsg) infoMsg$: Observable<string>;
    @Select(AlertState.getType) type$: Observable<string>;

    public alertTypes = AlertTypesEnum;

    constructor(private store: Store) { }

    ngOnInit(): void {
        // setTimeout(() => {
        //     this.store.dispatch(new SetInfoMsg('Nouveau chantier créé'));

        // }, 0);
    }

    public onClick(): void {
        this.store.dispatch(new ResetInfoMsg());
    }
}
