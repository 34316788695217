import { IOffer } from './../../../views/sites/interfaces/offer.interface';

export class CreateOffer {
    static readonly type = '[Offer] Create';

    constructor(public offer: IOffer) { }
}

export class UpdateOffer {
    static readonly type = '[Offer] Update';

    constructor(public offer: IOffer) { }
}

export class GetAllOffers {
    static readonly type = '[Offer] Get List';

    constructor(public siteId: number) { }
}

// export class GetOfferById {
// }

export class DeleteOffer {
    static readonly type = '[Offer] Delete';

    constructor(public id: number) { }
}

