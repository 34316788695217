import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';
import { IStat, IStatDtoOut, IStatListDtoOut } from '../dto/statDto';

@Injectable({
    providedIn: 'root'
})
export class StatsService {

    constructor(private http: HttpClient) { }

    getStats(): Observable<IStat> {
        return this.http.get<IStatListDtoOut>(environment.API_URL + SubUrlEnum.STATS_GET)
            .pipe(
                map(result => {
                    return this.statMapper(result.stats);
                })
            );
    }

    private statMapper(stats: IStatDtoOut): IStat {
        return {
            nbSites: stats.number_sites,
            nbVisits: stats.number_vists,
            nbDIU: stats.number_diu,
            nbPSS: stats.number_analyses
        };
    }

}
