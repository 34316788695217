import { INewVisitDtoOut } from './../../../views/sites/interfaces/reports.interface';

export class GetVisitingReportsBySiteId {
    static readonly type = '[VisitingReports] Get Visiting Reports By Site Id';

    constructor(public payload: { siteId: number }) { }
}

export class GetVisitingReportById {
    static readonly type = '[VisitingReports] Get Visiting Report By Id';

    constructor(public payload: { id: number }) { }
}

export class CreateVisitingReport {
    static readonly type = '[VisitingReports] Create Visiting Report';

    constructor(public payload: { visitingReport: INewVisitDtoOut }) { }
}

export class UpdateVisitingReport {
    static readonly type = '[VisitingReports] Update Visiting Report';

    constructor(public payload: { visitingReport: INewVisitDtoOut }) { }
}

export class DeleteVisitingReport {
    static readonly type = '[VisitingReports] Delete Visiting Report';

    constructor(public payload: { id: number }) { }
}

export class ResetVisit {
    static readonly type = '[VisitingReports] Reset current visit';
}
export class ResetVisits {
    static readonly type = '[VisitingReports] Reset visits';
}
