import { IJobDto } from '../dtos/job-dto.interface';

export class JobModel {
    id: number;
    name: string;
    activitiesIds: number[];

    constructor(base?: Partial<JobModel>) {
        Object.assign(this, base);
    }

    public fromDto?(dto: IJobDto): JobModel {
        this.id = dto.id ? dto.id : null;
        this.name = dto.name ? dto.name : null;
        this.activitiesIds = dto.activity_ids ? dto.activity_ids : null;

        return this;
    }

    public toDto?(): IJobDto {
        const dto: IJobDto = {
            id: this.id ? this.id : null,
            name: this.name ? this.name : null,
            activity_ids: this.activitiesIds ? this.activitiesIds : null,
        };

        return dto;
    }
}
