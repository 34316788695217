import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HeaderErrorEnum } from './../enums/header-errors.enum';
import { SetErrors } from './../store/actions/error.actions';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private readonly GENERIC_ERROR = 'Le serveur a rencontré une erreur, veuillez réessayer plus tard.';

    constructor(private store: Store, private router: Router) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(error => {
                if (request.headers.get(HeaderErrorEnum.OWN_ERROR) === 'true') {
                    // throw in component, will enter state catchError block, more specific error
                    return throwError(error);
                }

                // Set global state error and display the error msg in an alert
                if (error.error.errors || error.error.error) {
                    this.store.dispatch(new SetErrors(error.error.errors || [error.error.error]));
                } else {
                    this.store.dispatch(new SetErrors(this.GENERIC_ERROR));
                }

                return of(error);
            })
        );
    }
}
