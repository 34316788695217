import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { DataTablesModule } from 'angular-datatables';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { componentsShared } from './components';
import { sharedDirectives } from './directives';
import { ActivityState } from './store/state/activity.state';
import { AlertState } from './store/state/alert.state';
import { AuthState } from './store/state/auth.state';
import { SiteConventionState } from './store/state/conventions.state';
import { DIUState } from './store/state/diu.state';
import { ErrorsState } from './store/state/errors.state';
import { IntervenantsState } from './store/state/intervenants.state';
import { JobState } from './store/state/job.state';
import { LoaderState } from './store/state/loader.state';
import { MeasureState } from './store/state/measures.state';
import { OffersState } from './store/state/offers.state';
import { PageTitleState } from './store/state/page-title.state';
import { RoleState } from './store/state/role.state';
import { SiteCategoryState } from './store/state/site-category.state';
import { SiteRisksState } from './store/state/site-risks.state';
import { SiteState } from './store/state/site.state';
import { StatsState } from './store/state/stats.state';
import { UsersState } from './store/state/users.state';
import { VisitingReportsState } from './store/state/visiting-report.state';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxsModule.forFeature([
            AuthState,
            ActivityState,
            UsersState,
            JobState,
            IntervenantsState,
            StatsState,
            PageTitleState,
            RoleState,
            SiteState,
            ErrorsState,
            MeasureState,
            SiteCategoryState,
            LoaderState,
            SiteRisksState,
            SiteConventionState,
            DIUState,
            OffersState,
            AlertState,
            VisitingReportsState,
        ]),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.email', 'auth.accessToken', 'auth.refreshToken'],
        }),
        AutocompleteLibModule,
        DataTablesModule.forRoot(),
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        BsDatepickerModule.forRoot(),
    ],
    providers: [BsDatepickerConfig],
    declarations: [...componentsShared, ...sharedDirectives],
    exports: [
        ...componentsShared,
        ...sharedDirectives,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        DataTablesModule,
        NgMultiSelectDropDownModule,
        BsDatepickerModule,
    ],
})
export class SharedModule {
    constructor(localService: BsLocaleService) {
        defineLocale('fr', frLocale);
        localService.use('fr');
    }
}
