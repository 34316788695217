import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { id } from '@swimlane/ngx-charts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubUrlEnum } from 'src/shared/enums/sub-urls-enum';
import { IdTitle } from 'src/shared/interfaces/global.interface';

import { environment } from './../../../environments/environment';
import { DIU, DIUDtoIn, DIUDtoOut, DIUsDtoIn } from './../interfaces/diu.interface';


@Injectable({
    providedIn: 'root'
})
export class DiuService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient) { }

    public getAllDIU(siteId: string) {
        return this.http.get<DIUsDtoIn>(environment.API_URL + SubUrlEnum.DOCUMENTS.replace('{id}', siteId))
            .pipe(
                map(result => {
                    const documents = result.documents.map(diu => {
                        return this.DIUBackToFront(diu);
                    });
                    return documents;
                })
            );
    }

    public getDocumentById(documentId: number) {
        return this.http.get<DIUDtoIn>(environment.API_URL + SubUrlEnum.DOCUMENT.replace('{id}', documentId.toString()))
            .pipe(
                map(result => {
                    return this.DIUBackToFront(result);
                })
            );
    }

    public uploadDocument(doc: DIUDtoOut): Observable<DIU> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            })
        };
        const formData = new FormData();
        formData.append('document[file]', doc.file);
        formData.append('document[title]', doc.title);
        formData.append('document[site_id]', doc.site_id.toString());
        formData.append('document[document_type_id]', doc.document_type_id.toString());

        return this.http.post<DIUDtoIn>(
            environment.API_URL + SubUrlEnum.SITE_DIU_UPLOAD,
            formData,
            httpOptions
        ).pipe(
            map(response => {
                const newDoc = this.DIUBackToFront(response);
                return newDoc;
            })
        );
    }

    public updateDocument(docId: string, doc: DIUDtoOut): Observable<DIU> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            })
        };
        const formData = new FormData();
        formData.append('document[site_id]', doc.site_id.toString());
        if (doc.title) { formData.append('document[title]', doc.title); }
        if (doc.document_type_id) { formData.append('document[document_type_id]', doc.document_type_id.toString()); }
        // formData.append('document[file]', null); -> peut-on update le doc ? = re-upload ?

        return this.http.patch<DIUDtoIn>(
            environment.API_URL + SubUrlEnum.DOCUMENT_UPDATE.replace('{id}', docId),
            formData,
            httpOptions
        ).pipe(
            map(response => {
                const newDoc = this.DIUBackToFront(response);
                return newDoc;
            })
        );
    }

    public deleteDocument(docId: string): Observable<boolean> {
        return this.http.delete(environment.API_URL + SubUrlEnum.SITE_DIU_DELETE + docId, {})
            .pipe(
                map(_ => {
                    return true;
                })
            );
    }

    public getDocumentTypes(): Observable<IdTitle[]> {
        return this.http.get<any>(environment.API_URL + SubUrlEnum.DOCUMENT_TYPES)
            .pipe(
                map(result => {
                    return result.document_types;
                })
            );
    }

    private DIUBackToFront(diu: DIUDtoIn): DIU {
        return {
            id: diu.id,
            title: diu.title,
            siteId: diu.site_id,
            documentTypeId: diu.document_type_id,
            fileURL: 'https://symbiose.dev.itw.dgnus.net:8080' + diu.file_url
        };
    }

    private DIUFrontToBack(diu: DIU): DIUDtoIn {
        return {
            id: diu.id,
            title: diu.title,
            site_id: diu.siteId,
            document_type_id: diu.documentTypeId,
            file_url: diu.fileURL
        };
    }
}
