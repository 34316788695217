import { AlertComponent } from './alert/alert.component';
import { ErrorComponent } from './error/error.component';
import { LoaderComponent } from './loader/loader.component';
import { LogoComponent } from './logo/logo.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TopnavComponent } from './topnav/topnav.component';

export const componentsApp: any[] = [
    LogoComponent,
    SidemenuComponent,
    TopnavComponent,
    ErrorComponent,
    LoaderComponent,
    AlertComponent
];

export * from './logo/logo.component';
export * from './sidemenu/sidemenu.component';
export * from './topnav/topnav.component';
export * from './error/error.component';
export * from './loader/loader.component';
export * from './alert/alert.component';
