export class HospitalAddressModel {
    postcode: number;
    city: string;
    street: string;
    number: string;
    name: string;

    constructor(base: Partial<HospitalAddressModel> = {}) {
        Object.assign(this, base);
    }
}
