import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-modal-validation',
    templateUrl: './modal-validation.component.html',
    styleUrls: ['./modal-validation.component.scss']
})

export class ModalValidationComponent implements OnInit {

    public onClose: Subject<boolean>;

    public title: string;
    public message: string;

    constructor(public bsModalRef: BsModalRef) { }

    ngOnInit(): void {
        this.onClose = new Subject();
    }

    public closeModal(): void {
        this.bsModalRef.hide();
    }

    public confirmModal(value: boolean): void {
        this.onClose.next(value ? true : false);
        this.closeModal();
    }
}