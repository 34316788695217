import { IMeasure } from 'src/views/activities/DTO/measure.interface';

export class CreateMeasure {
    static readonly type = '[Measures] Create';

    constructor(public measure: IMeasure) { }
}

export class EditMeasure {
    static readonly type = '[Measures] Edit';

    constructor(public measure: IMeasure) { }
}

export class GetMeasureById {
    static readonly type = '[Measure] GetMeasureById';
    constructor(public id: number) { }
}

export class GetAllMeasures {
    static readonly type = '[Measure] Get list';
    constructor() { }
}

export class DeleteMeasure {
    static readonly type = '[Measure] Delete';
    constructor(public id: number) { }
}
export class ResetMeasures {
    static readonly type = '[Measure] Reset';
}
