import { HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

import { IAnalyseDTOIn } from './../../views/sites/interfaces/pss.interface';

export const mockAnalysisRes = (request: HttpRequest<any>) => {
    const body: IAnalyseDTOIn = {
        analyses: [
            // {
            //     id: 1,
            //     name: 'test analyse 1',
            //     pss: [
            //         {
            //             id: 12,
            //             gravity: {
            //                 id: 36,
            //                 name: 'gravité',
            //             },
            //             frequency: {
            //                 id: 49,
            //                 name: 'fréquence',
            //             },
            //             probability: {
            //                 id: 22,
            //                 name: 'probabilité',
            //             },
            //             risk_management: {
            //                 id: 11,
            //                 name: 'risques encadrés',
            //             },
            //             job: {
            //                 id: 28,
            //                 name: 'Plombier - Chauffagiste',
            //                 activity_ids: [28, 29],
            //             },
            //             activity: {
            //                 id: 27,
            //                 name: 'Activity A2',
            //                 risk_ids: [64, 65],
            //             },
            //             measure_ids: [4, 5],
            //             risk: {
            //                 id: 64,
            //                 complete_risk: 'Risque complet 2',
            //                 simplified_risk: 'Risque simplifié A2',
            //             },
            //         },
            //     ],
            // },
        ],
    };

    const res = {
        status: 200,
        body: body,
    };
    return of(new HttpResponse(res));
};
