import { DIUDtoOut } from 'src/views/sites/interfaces/diu.interface';

export class GetAllDIU {
    static readonly type = '[DIU] Get all';
    constructor(public payload: { siteId: string; }) { }
}

export class UploadDIU {
    static readonly type = '[DIU] Upload';
    constructor(public payload: { doc: DIUDtoOut; }) { }
}

export class GetDocumentById {
    static readonly type = '[DIU] Get doc by id';
    constructor(public payload: { docId: number; }) { }
}

export class DeleteDIU {
    static readonly type = '[DIU] Delete';
    constructor(public payload: { docId: number; }) { }
}

export class ResetDIUs {
    static readonly type = '[DIU] Reset docs';
}

export class ResetDIU {
    static readonly type = '[DIU] Reset doc';
}

export class GetDocumentTypes {
    static readonly type = '[DIU] Doc types';
}

export class UpdateDocument {
    static readonly type = '[DIU] update doc';
    constructor(public payload: { docId: string; doc: DIUDtoOut }) { }
}
