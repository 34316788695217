import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ResetErrors, SetErrors } from './../actions/error.actions';

export interface ErrorsStateModel {
    errors: string[];
    infoMsg: string;
}

@State<ErrorsStateModel>({
    name: 'errors',
    defaults: {
        errors: [],
        infoMsg: '',
    },
})
@Injectable()
export class ErrorsState {
    constructor() {}

    private errorTimeout: ReturnType<typeof setTimeout>;

    private timeOut = 4000;

    @Selector()
    static getErrors(state: ErrorsStateModel): string[] {
        return state.errors;
    }

    @Action(SetErrors)
    setErrors(ctx: StateContext<ErrorsStateModel>, payload: SetErrors): void {
        const state = ctx.getState();

        ctx.patchState({
            errors: typeof payload.errors === 'string' ? [...state.errors, payload.errors] : [...state.errors, ...payload.errors],
        });

        if (this.errorTimeout) {
            clearTimeout(this.errorTimeout);
        }

        // Auto Dismiss errors after 4 seconds
        this.errorTimeout = setTimeout(() => {
            ctx.dispatch(new ResetErrors());
        }, this.timeOut);
    }

    @Action(ResetErrors)
    resetErrors(ctx: StateContext<ErrorsStateModel>): void {
        ctx.patchState({
            errors: [],
        });
    }
}
