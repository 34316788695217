import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIntervenants } from 'src/views/intervenants/DTO/intervenant.interface';

import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';
import { IntervenantModel } from '../../intervenants/models/intervenant.model';
import { environment } from './../../../environments/environment';
import { IIntervenant } from './../../intervenants/DTO/intervenant.interface';


@Injectable({
    providedIn: 'root'
})
export class IntervenantsService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient) { }

    public getAllIntervenants(): Observable<IntervenantModel[]> {
        return this.http.get<IIntervenants>(environment.API_URL + SubUrlEnum.INTERVENANTS_LIST)
            .pipe(
                map(data => {
                    return data.participants.map(intervenantDto => new IntervenantModel({}).fromDto(intervenantDto));
                }));
    }

    public getIntervenantById(id: number): Observable<IntervenantModel> {
        return this.http.get<IntervenantModel>(environment.API_URL + SubUrlEnum.INTERVENANTS_LIST + id);
    }

    public updateIntervenant(intervenant: IntervenantModel): Observable<IntervenantModel> {
        const backendIntervenant = intervenant.toDto();

        return this.http.patch<IIntervenant>(environment.API_URL + SubUrlEnum.INTERVENANTS_UPDATE + intervenant.id, backendIntervenant)
            .pipe(
                map(data => new IntervenantModel({}).fromDto(data))
            );
    }

    public createIntervenant(intervenant: IntervenantModel): Observable<IntervenantModel> {
        const backendIntervenant = intervenant.toDto();

        return this.http.post<IIntervenant>(environment.API_URL + SubUrlEnum.INTERVENANTS_ADD, backendIntervenant).pipe(map(data => {
            return new IntervenantModel({}).fromDto(data);
        }));
    }

    public deleteIntervenant(id: number): Observable<IntervenantModel[]> {
        return this.http.delete<IntervenantModel[]>(environment.API_URL + SubUrlEnum.INTERVENANTS_DELETE + id);
    }

}
