import { HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';

export const mockProgressRes = (request: HttpRequest<any>) => {
    const progressLevel = Math.floor((Math.random() * 6) + 1);

    if (request.method === 'GET') {
        const response = {
            status: 200,
            body: { level: progressLevel }
        };

        return of(new HttpResponse(response));
    }

    return of(new HttpResponse());
};
