<div class="images-preview">
    <div *ngFor="let imageFormGroup of imageFormArray.controls; let i = index">
        <div [formGroup]="imageFormGroup" class="image" *ngIf="!imageFormGroup.get('_destroy').value">
            <img [src]="imageFormGroup.get('url').value" />
            <button (click)="removeImage(i)">X</button>
        </div>
    </div>
</div>

<input type="file" accept="image/*" class="btn" (change)="uploadImage($event)" />
