import { JobModel } from './../../../views/jobs/models/job.model';

export class CreateJob {
    static readonly type = '[Job] Create';
    constructor(public job: JobModel) { }
}

export class EditJob {
    static readonly type = '[Job] Edit';
    constructor(public job: JobModel) { }
}

export class GetJobById {
    static readonly type = '[Job] GetJobById';
    constructor(public id: number) { }
}

export class GetAllJobs {
    static readonly type = '[Job] Get list';
    constructor() { }
}

export class DeleteJob {
    static readonly type = '[Job] Delete';
    constructor(public id: number) { }
}
