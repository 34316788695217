import { IAdress } from '../dtos/ISite.interface';

export class Adress {
    //   id: number;
    postcode: number;
    city: string;
    street: string;
    number: string;

    constructor(base: Partial<Adress>) {
        this.initalize();
        Object.assign(this, base);
    }

    public static fromDto(adress: IAdress): Adress {
        return new Adress({
            postcode: adress && adress.postcode || undefined,
            city: adress && adress.city || null,
            street: adress && adress.street || null,
            number: adress && adress.number || undefined
        });
    }

    private initalize() {
        this.postcode = undefined;
        this.city = undefined;
        this.street = undefined;
        this.number = undefined;
    }
}
