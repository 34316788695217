/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubUrlEnum } from 'src/shared/enums/sub-urls-enum';

import { environment } from '../../../environments/environment';
import {
    IDocumentIn,
    IDocumentsIn,
    INewVisitDtoOut,
    IVisitReport,
    IVisitReportDTOIn,
    IVisitReportsDTOIn,
} from './../interfaces/reports.interface';

@Injectable({
    providedIn: 'root',
})
export class VisitingReportService {
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    constructor(private http: HttpClient) {}

    public getVisitingReportsBySiteId(siteId: number): Observable<IVisitReport[]> {
        return this.http
            .get<IVisitReportsDTOIn>(environment.API_URL + SubUrlEnum.VISITING_REPORT_LIST + 'site_id=' + siteId)
            .pipe(map((data) => data.visits.map((element) => this.visitReportBackToFront(element))));
    }

    public getVisitingReportById(id: number): Observable<IVisitReport> {
        return this.http
            .get<IVisitReportDTOIn>(environment.API_URL + SubUrlEnum.VISITING_REPORT_GET + id)
            .pipe(map((data: any) => (data ? this.visitReportBackToFront(data) : null)));
    }

    public deleteVisitingReport(id: number): Observable<IVisitReport> {
        return this.http.delete<any>(environment.API_URL + SubUrlEnum.VISITING_REPORT_DELETE + id);
    }

    public createVisitingReport(visit: INewVisitDtoOut): Observable<IVisitReport> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            }),
        };

        const formData = new FormData();
        formData.append('visit[site_id]', visit.siteId.toString());
        formData.append('visit[visit_name]', visit.visitName);
        formData.append('visit[start_date]', visit.startDate);
        formData.append('visit[accident_comment]', visit.accidentComment);
        formData.append('visit[last_report_visit_approval]', visit.lastReportVisitApproval ? 'true' : 'false');
        formData.append('visit[safety_plan_received]', visit.safetyPlanReceived ? 'true' : 'false');
        formData.append('visit[divers]', visit.divers);

        visit.constatsAttributes.forEach((c) => {
            formData.append('visit[constats_attributes][][constat]', c.constat);
            formData.append('visit[constats_attributes][][proposition]', c.proposition);
            formData.append('visit[constats_attributes][][follow_up]', c.followUp ? c.followUp : '');
            formData.append('visit[constats_attributes][][is_complete]', c.isComplete ? 'true' : 'false');
            if (c.images) {
                c.images.forEach((img) => {
                    if (img.file) {
                        formData.append('visit[constats_attributes][][images_attributes][][file]', img.file[0]);
                    }
                });
            }
        });

        visit.documents.forEach((doc) => {
            formData.append('visit[document_visits_attributes][][document_id]', doc.document_id.toString());
            formData.append('visit[document_visits_attributes][][comment]', doc.comment);
        });

        return this.http
            .post<any>(environment.API_URL + SubUrlEnum.VISITING_REPORT_ADD, formData, httpOptions)
            .pipe(map((result) => this.visitReportBackToFront(result)));
    }

    public updateVisitingReport(visit: INewVisitDtoOut): Observable<IVisitReport> {
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            }),
        };

        const formData = new FormData();
        formData.append('visit[site_id]', visit.siteId.toString());
        if (visit.visitName) {
            formData.append('visit[visit_name]', visit.visitName);
        }
        if (visit.startDate) {
            formData.append('visit[start_date]', visit.startDate);
        }
        if (visit.accidentComment) {
            formData.append('visit[accident_comment]', visit.accidentComment);
        }
        if (visit.lastReportVisitApproval) {
            formData.append('visit[last_report_visit_approval]', visit.lastReportVisitApproval ? 'true' : 'false');
        }
        if (visit.safetyPlanReceived) {
            formData.append('visit[safety_plan_received]', visit.safetyPlanReceived ? 'true' : 'false');
        }
        if (visit.divers) {
            formData.append('visit[divers]', visit.divers);
        }
        if (visit.participants) {
            visit.participants.forEach((participant) => {
                formData.append('visit[participants][][id]', participant.participantId);
            });
        }
        if (visit.constatsAttributes) {
            visit.constatsAttributes.forEach((c) => {
                formData.append('visit[constats_attributes][][id]', c.id.toString());
                formData.append('visit[constats_attributes][][constat]', c.constat);
                formData.append('visit[constats_attributes][][proposition]', c.proposition);
                formData.append('visit[constats_attributes][][follow_up]', c.followUp ? c.followUp : '');
                formData.append('visit[constats_attributes][][is_complete]', c.isComplete ? 'true' : 'false');
                if (c.images) {
                    c.images.forEach((img) => {
                        if (img._destroy) {
                            formData.append('visit[constats_attributes][][images_attributes][][id]', img.id.toString());
                            formData.append('visit[constats_attributes][][images_attributes][][_destroy]', 'true');
                        } else if (!img.url && img.file) {
                            formData.append('visit[constats_attributes][][images_attributes][][file]', img.file);
                        }
                    });
                }
            });
        }
        if (visit.documents) {
            visit.documents.forEach((doc) => {
                if (doc.id) {
                    formData.append('visit[document_visits_attributes][][id]', doc.id.toString()); // id du doc dans le raport
                }
                if (doc._destroy) {
                    formData.append('visit[document_visits_attributes][][_destroy]', 'true');
                } else {
                    formData.append('visit[document_visits_attributes][][document_id]', doc.document_id.toString()); // id du doc
                    formData.append('visit[document_visits_attributes][][comment]', doc.comment);
                }
            });
        }

        return this.http
            .patch<any>(environment.API_URL + SubUrlEnum.VISITING_REPORT_ADD + visit.id, formData, httpOptions)
            .pipe(map((result: any) => this.visitReportBackToFront(result)));
    }

    public getDocumentsBySiteId(siteId: string): Observable<IDocumentIn[]> {
        return this.http.get<IDocumentsIn>(environment.API_URL + 'documents?site_id=' + siteId).pipe(map((data) => data.documents));
    }

    // MAPPERS

    public visitReportBackToFront(visit: IVisitReportDTOIn): IVisitReport {
        return {
            id: visit.id,
            observations: visit.constats,
            visitName: visit.visit_name,
            visitDate: visit.start_date,
            siteId: visit.site_id,
            previousObservation: visit.previous_constats,
            lastReporVisitApproval: visit.last_report_visit_approval,
            accident: !!visit.accident_comment,
            accidentComment: visit.accident_comment,
            safetyPlanReceived: visit.safety_plan_received,
            divers: visit.divers,
            documents: visit.documents,
        };
    }
}
