export interface AuthStateModel {
    token: string | null;
    username: string | null;
}

export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { email: string; password: string }) { }
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class RefreshSession {
    static readonly type = '[Auth] Refresh Session';
}

export class ResetErrors {
    static readonly type = '[Auth] Reset errors'
}

