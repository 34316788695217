import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IConvention } from 'src/views/sites/interfaces/convention.interface';

import { SiteConventionService } from './../../../views/sites/services/conventions.service';
import {
    AddSignedConvention,
    GenerateConvention,
    GetAllConvention,
    ResetConventions,
} from './../actions/conventions.action';

export class ConventionStateModel {
    public conventions: IConvention[];
}

@State<ConventionStateModel>({
    name: 'convention',
    defaults: {
        conventions: [],
    },
})
@Injectable()
export class SiteConventionState {
    constructor(private conventionService: SiteConventionService) {}

    @Selector()
    public static getAllConvention(state: ConventionStateModel): IConvention[] {
        return state.conventions;
    }

    @Action(GetAllConvention)
    public getAllConvention(ctx: StateContext<ConventionStateModel>, action: GetAllConvention): Observable<any> {
        const state = ctx.getState();
        if (state.conventions.length) {
            return;
        }

        return this.conventionService.getConventions(action.payload.siteId).pipe(
            tap((result) => {
                ctx.patchState({
                    conventions: result,
                });
            })
        );
    }

    @Action(ResetConventions)
    public resetConventions(ctx: StateContext<ConventionStateModel>): void {
        ctx.patchState({
            conventions: [],
        });
    }

    @Action(GenerateConvention)
    public generateConvention(ctx: StateContext<ConventionStateModel>, action: GenerateConvention): Observable<any> {
        const state = ctx.getState();
        return this.conventionService.generateConvention(action.payload.type, action.payload.siteId).pipe(
            tap((result) => {
                ctx.patchState({
                    conventions: [...state.conventions, result],
                });
            })
        );
    }

    @Action(AddSignedConvention)
    public addSignedConvention(ctx: StateContext<ConventionStateModel>, action: AddSignedConvention): Observable<any> {
        return this.conventionService
            .sendSignedConvention(action.payload.siteId, action.payload.conventionId, action.payload.conventionSigned)
            .pipe(
                tap((result) => {
                    ctx.setState(
                        patch({
                            conventions: updateItem<IConvention>((convention) => convention.id === result.id, result),
                        })
                    );
                })
            );
    }
}
