import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';
import { IUsers } from '../DTO/user.interface';
import { UserModel } from '../models/user.model';
import { HeaderErrorEnum } from './../../../shared/enums/header-errors.enum';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    public getAllUsers(): Observable<UserModel[]> {
        return this.http.get<IUsers>(environment.API_URL + SubUrlEnum.GET_ALL_USERS).pipe(
            map((usersDTO) => {
                return usersDTO ? usersDTO.users.map((user) => UserModel.fromDto(user)) : null;
            })
        );
    }

    public getUserById(id: number): Observable<UserModel> {
        return this.http.get<UserModel>(environment.API_URL + SubUrlEnum.GET_USER + id);
    }

    public updateUser(user: UserModel): Observable<UserModel> {
        return this.http.put<UserModel>(environment.API_URL + SubUrlEnum.UPDATE_USER + user.id, { user });
    }

    public createUser(user: UserModel): Observable<UserModel> {
        const headers = new HttpHeaders().set(HeaderErrorEnum.OWN_ERROR, 'true');

        return this.http.post<UserModel>(environment.API_URL + SubUrlEnum.CREATE_USER, { user }, { headers });
    }

    public deleteUser(id: number): Observable<UserModel[]> {
        return this.http.delete<UserModel[]>(environment.API_URL + SubUrlEnum.DELETE_USER + id);
    }
}
