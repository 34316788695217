import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-main-add-button',
    templateUrl: './main-add-button.component.html',
    styleUrls: ['./main-add-button.component.scss'],
})
export class MainAddButtonComponent {
    @Input() icon: string;
    @Input() text: string;

    @Output() clickedButton = new EventEmitter();

    public onClick(): boolean {
        this.clickedButton.emit();
        return false;
    }
}
