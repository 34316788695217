import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Role } from '../../../../src/views/sites/models/role.model';
import { RoleService } from './../../../views/sites/services/role.service';
import { GetAllRoles } from './../actions/role.action';

export class RolesStateModel {
    roles: Role[];
    role: Role;
    isListFetched: boolean;
}

@State<RolesStateModel>({
    name: 'roles',
    defaults: {
        roles: [],
        role: null,
        isListFetched: false
    }
})

@Injectable()
export class RoleState {
    constructor(private roleService: RoleService) { }

    @Selector()
    static getAllRoles(state: RolesStateModel) {
        return state.roles;
    }

    @Action(GetAllRoles)
    getAllRoles(ctx: StateContext<RolesStateModel>) {
        const state = ctx.getState();

        if (state.isListFetched) {
            return;
        }

        return this.roleService.getAllRoles().pipe(tap((result) => {
            ctx.patchState({
                roles: result,
                isListFetched: true
            });
        }));
    }
}
