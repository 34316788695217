import { HttpRequest, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { IOffer } from 'src/views/sites/interfaces/offer.interface';

import { mockOffers } from './mock-data';

export const mockOffersRes = (request: HttpRequest<any>) => {
    const offers = mockOffers;
    const id: number = parseInt(request.params.get('id'), 10) ?? undefined;

    if (request.method === 'GET' && !id) {
        const response = {
            status: 200,
            body: offers
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'POST') {
        const newId = Math.floor(Math.random() * 1000);
        const mockUrl = 'https://thumbs.dreamstime.com/b/special-offer-ribbon-special-offer-ribbon-special-offer-banner-sign-special-offer-153373285.jpg';

        const newOffer: Partial<IOffer> = {};

        for (const pair of request.body.entries()) {
            newOffer[pair[0]] = pair[1];
        }

        newOffer.id = newId;
        newOffer.fileUrl = mockUrl;

        const response = {
            status: 200,
            body: newOffer
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'PUT') {
        const newOffer = request.body;

        const response = {
            status: 200,
            body: newOffer
        };

        return of(new HttpResponse(response));
    }

    if (request.method === 'DELETE') {
        const response = {
            status: 204
        };

        return of(new HttpResponse(response));
    }

    return of(new HttpResponse());
};
