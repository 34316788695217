import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMeasure, IMeasures } from 'src/views/activities/DTO/measure.interface';

import { environment } from '../../../environments/environment';
import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';

@Injectable({
    providedIn: 'root'
})
export class MeasuresService {

    constructor(private http: HttpClient) { }

    getAllMeasures(): Observable<IMeasure[]> {
        return this.http.get<IMeasures>(environment.API_URL + SubUrlEnum.MEASURES)
            .pipe(
                map(results => {
                    return results.measures;
                }));
    }

    getMeasureById(id: number): Observable<IMeasure> {
        return this.http.get<IMeasure>(environment.API_URL + SubUrlEnum.MEASURE.replace('{id}', id.toString()));
    }

    createMeasure(measure: IMeasure): Observable<IMeasure> {
        return this.http.post<IMeasure>(environment.API_URL + SubUrlEnum.MEASURES, measure)
    }

    updateMeasure(measure: IMeasure): Observable<IMeasure> {
        return this.http.patch<IMeasure>(environment.API_URL + SubUrlEnum.MEASURE.replace('{id}', measure.id.toString()), measure)
    }

    deleteMeasure(id: number): Observable<void> {
        return this.http.delete<void>(environment.API_URL + SubUrlEnum.MEASURE.replace('{id}', id.toString()));
    }

}
