import { IPartialParticipant } from '../dtos/ISite.interface';

export class PartialParticipant {

    constructor(base: Partial<PartialParticipant>) {
        Object.assign(this, base);
    }

    id: number;
    participantId: number;
    roleId: number;
    _destroy?: boolean;

    public static fromDto(participant: IPartialParticipant): PartialParticipant {
        return new PartialParticipant({
            id: participant && participant.id || undefined,
            participantId: participant && participant.participant_id || undefined,
            roleId: participant && participant.role_id || undefined
        });
    }

    public static toDto(partipant: PartialParticipant): any {
        return {
            id: partipant.id,
            participant_id: partipant.participantId,
            role_id: partipant.roleId,
            _destroy: partipant._destroy
        };
    }
}
