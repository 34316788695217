import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs-compat';
import { mergeMap, tap } from 'rxjs/operators';
import { SetPageTitle } from 'src/shared/store/actions/page-title.actions';

import { Site } from './../../../views/sites/models/site.model';
import { SiteService } from './../../../views/sites/services/sites.service';
import {
    CreateSite,
    DeleteSite,
    EditSite,
    GetAllSites,
    GetSiteById,
    ResetSite,
    ResetSiteFilter,
    SetSiteFilter,
} from './../actions/site.action';

export class SitesStateModel {
    public sites: Site[];
    public site: Site;
    public filteredSites: Site[];
    public isListFetched: boolean;
}

@State<SitesStateModel>({
    name: 'sites',
    defaults: {
        sites: [],
        site: null,
        filteredSites: [],
        isListFetched: false,
    },
})
@Injectable()
export class SiteState {
    constructor(private siteService: SiteService) {}

    @Selector()
    public static getAllSites(state: SitesStateModel): Site[] {
        return state.sites;
    }

    @Selector()
    public static getSiteById(state: SitesStateModel): Site {
        return state.site;
    }

    @Selector()
    public static getFilteredSites(state: SitesStateModel): Site[] {
        return state.filteredSites;
    }

    @Action(CreateSite)
    public create(ctx: StateContext<SitesStateModel>, action: CreateSite): Observable<void> {
        return this.siteService.createSite(action.payload.site).pipe(
            tap((result: Site) => {
                ctx.setState(
                    patch({
                        sites: insertItem<Site>(result, 0),
                        site: result,
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new ResetSiteFilter()))
        );
    }

    @Action(EditSite)
    public editSite(ctx: StateContext<SitesStateModel>, action: EditSite): Observable<void> {
        return this.siteService.updateSite(action.payload.site).pipe(
            tap((result: Site) => {
                ctx.setState(
                    patch({
                        site: result,
                        sites: updateItem<Site>((site) => site.id === result.id, result),
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new ResetSiteFilter()))
        );
    }

    @Action(GetSiteById)
    public getSiteById(ctx: StateContext<SitesStateModel>, action: GetSiteById): Observable<void | Site> | SitesStateModel {
        return this.siteService.getSiteById(action.payload.id.toString()).pipe(
            tap((result: Site) => {
                ctx.patchState({
                    site: result,
                });
                ctx.dispatch(new SetPageTitle(result.name));
            })
        );
    }

    @Action(GetAllSites)
    public getAllSites(ctx: StateContext<SitesStateModel>): Observable<Site[]> {
        return this.siteService.getAllSites().pipe(
            tap((result: Site[]) => {
                const sortedSites: Site[] = result.sort(this.sortSitesChronologically);

                ctx.patchState({
                    sites: sortedSites,
                    filteredSites: sortedSites,
                    isListFetched: true,
                });
            })
        );
    }

    @Action(DeleteSite)
    public deleteSite(ctx: StateContext<SitesStateModel>, action: DeleteSite): Observable<void> {
        const state: SitesStateModel = ctx.getState();

        return this.siteService.deleteSite(action.payload.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        sites: removeItem<Site>((site) => site.id === action.payload.id),
                        site: iif<Site>(state.site?.id === action.payload.id, null, state.site),
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new ResetSiteFilter()))
        );
    }

    @Action(ResetSite)
    public resetSite(ctx: StateContext<SitesStateModel>): void {
        ctx.patchState({
            site: undefined,
        });
    }

    @Action(SetSiteFilter)
    public setSiteFilter(ctx: StateContext<SitesStateModel>, action: SetSiteFilter): Observable<void> | boolean {
        const { filter } = action.payload;
        const state: SitesStateModel = ctx.getState();

        if (!filter) {
            return ctx.dispatch(new ResetSiteFilter());
        }

        const filteredSites: Site[] = state.sites.filter((site) => {
            const fieldsToCheck: string[] = [
                site.name.toLowerCase(),
                site.workType?.toLowerCase() || '',
                site.adress?.city.toLowerCase() || '',
                site.adress?.number.toString() || '',
                site.adress?.postcode.toString() || '',
                site.adress?.street.toLowerCase() || '',
            ];

            const isRelevant: boolean = fieldsToCheck.some((element) => element.indexOf(filter) !== -1);

            return isRelevant;
        });

        ctx.patchState({
            filteredSites,
        });
    }

    @Action(ResetSiteFilter)
    public resetSiteFilter(ctx: StateContext<SitesStateModel>): void {
        const state: SitesStateModel = ctx.getState();

        ctx.patchState({
            filteredSites: state.sites,
        });
    }

    private sortSitesChronologically(a: Site, b: Site): number {
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
}
