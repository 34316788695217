import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Role } from '../models/role.model';
import { SubUrlEnum } from './../../../shared/enums/sub-urls-enum';


@Injectable({
    providedIn: 'root'
})
export class RoleService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient) { }

    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(environment.API_URL + SubUrlEnum.ROLES_LIST)
            .pipe(map((data: any) => data.roles));
    }
}
