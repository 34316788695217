import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { JobModel } from 'src/views/jobs/models/job.model';

import { CreateJob, DeleteJob, EditJob, GetAllJobs, GetJobById } from '../actions/job.actions';
import { JobService } from './../../../views/jobs/services/job.service';

export class JobsStateModel {
    jobs: JobModel[];
    job: JobModel;
    isListFetched: boolean;
}

@State<JobsStateModel>({
    name: 'jobs',
    defaults: {
        jobs: [],
        job: null,
        isListFetched: false
    }
})

@Injectable()
export class JobState {

    constructor(private jobService: JobService) { }

    @Selector()
    static getAllJobs(state: JobsStateModel) {
        return state.jobs;
    }

    @Selector()
    static getJobById(state: JobsStateModel) {
        return state.job;
    }

    @Action(CreateJob)
    create(ctx: StateContext<JobsStateModel>, action: CreateJob) {
        return this.jobService.create(action.job).pipe(
            tap((result: JobModel) => {
                ctx.setState(
                    patch({
                        jobs: append<JobModel>([result]),
                        job: result
                    })
                );
            })
        );
    }

    @Action(EditJob)
    editJob(ctx: StateContext<JobsStateModel>, action: EditJob) {
        return this.jobService.edit(action.job).pipe(
            tap((result: JobModel) => {
                ctx.setState(
                    patch({
                        jobs: updateItem<JobModel>(job => job.id === result.id, result),
                        job: result
                    })
                );
            })
        );
    }

    @Action(GetJobById)
    getJobById(ctx: StateContext<JobsStateModel>, action: GetJobById) {
        const state = ctx.getState();

        if (state.jobs.length) {
            const jobToFind = state.jobs.find(job => job.id === action.id);

            if (jobToFind) {
                return ctx.patchState({
                    job: jobToFind
                });
            }
        }

        return this.jobService.getJobById(action.id).pipe(tap((result) => {
            ctx.patchState({
                job: result
            });
        }));
    }

    @Action(GetAllJobs)
    getAllJobs(ctx: StateContext<JobsStateModel>) {
        const state = ctx.getState();

        if (state.isListFetched) {
            return;
        }

        return this.jobService.getAllJobs().pipe(tap((result) => {
            ctx.patchState({
                jobs: result,
                isListFetched: true
            });
        }));
    }

    @Action(DeleteJob)
    deleteJob(ctx: StateContext<JobsStateModel>, action: DeleteJob) {
        return this.jobService.deleteJob(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        jobs: removeItem<JobModel>(job => job.id === action.id),
                        job: null
                    })
                );
            })
        );
    }

}

