import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { Logout } from './../../../shared/store/actions/auth.actions';

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

    constructor(private store: Store) { }

    ngOnInit() {
    }

    public logout(): void {
        this.store.dispatch(new Logout());
    }

}
