import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRisk, IRiskDtoOut } from 'src/views/sites/interfaces/risk.interface';

import { environment } from './../../../environments/environment';
import { SubUrlEnum } from './../../../shared/enums/sub-urls-enum';
import { IActivities, IActivity, IActivityDtoOut } from './../DTO/activity.interface';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    constructor(private http: HttpClient) { }

    getAllActivities(): Observable<IActivity[]> {
        return this.http.get<IActivities>(environment.API_URL + SubUrlEnum.ACTIVITY_GET).pipe(
            map((results: IActivities) => {
                return results.activities
            })
        );
    }

    create(activity: IActivityDtoOut): Observable<IActivity> {
        return this.http.post<IActivity>(environment.API_URL + SubUrlEnum.ACTIVITY_ADD, activity).pipe(
            map((result: IActivity) => result)
        );
    }

    edit(activity: IActivityDtoOut): Observable<IActivity> {

        return this.http.put<IActivity>(environment.API_URL + SubUrlEnum.ACTIVITY_UPDATE + activity.id, { activity: activity }).pipe(
            map((result: IActivity) => result)
        );
    }

    getActivityById(id: number): Observable<IActivity> {
        return this.http.get<IActivity>(environment.API_URL + SubUrlEnum.ACTIVITY_GET + id).pipe(
            map((result: IActivity) => result)
        );
    }

    deleteActivity(id: number): Observable<void> {
        return this.http.delete<void>(environment.API_URL + SubUrlEnum.ACTIVITY_DELETE + id);
    }

    createRisk(risk: IRiskDtoOut): Observable<number> {
        return this.http.post(environment.API_URL + SubUrlEnum.RISK_ADD, risk).pipe(
            map((result: IRisk) => {
                return result.id
            })
        );
    }
}
