import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, iif, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { UserModel } from '../../../views/users/models/user.model';
import { UserService } from './../../../views/users/services/users.service';
import {
    CreateUser,
    DeleteUser,
    GetAllUsers,
    GetUserById,
    ResetUserFilter,
    SetUserFilter,
    UpdateUser,
} from './../actions/users.actions';

export class UsersStateModel {
    public users: UserModel[];
    public user: UserModel;
    public filteredUsers: UserModel[];
    public isListFetched: boolean;
}

@State<UsersStateModel>({
    name: 'users',
    defaults: {
        users: [],
        user: null,
        filteredUsers: [],
        isListFetched: false,
    },
})
@Injectable()
export class UsersState {
    constructor(private userService: UserService) {}

    @Selector()
    public static getAllUsers(state: UsersStateModel): UserModel[] {
        return state.users;
    }

    @Selector()
    public static getUserById(state: UsersStateModel): UserModel {
        return state.user;
    }

    @Selector()
    public static getFilteredUsers(state: UsersStateModel): UserModel[] {
        return state.filteredUsers;
    }

    @Action(GetAllUsers)
    public getAllUsers(ctx: StateContext<UsersStateModel>): Observable<UserModel[]> {
        return this.userService.getAllUsers().pipe(
            tap((result) => {
                ctx.patchState({
                    users: result,
                    filteredUsers: result,
                    isListFetched: true,
                });
            })
        );
    }

    @Action(GetUserById)
    public getUserById(ctx: StateContext<UsersStateModel>, action: GetUserById): Observable<UserModel> {
        return this.userService.getUserById(action.id).pipe(
            tap((result) => {
                ctx.patchState({
                    user: result,
                });
            })
        );
    }

    @Action(CreateUser)
    public createUser(ctx: StateContext<UsersStateModel>, action: CreateUser): Observable<any> {
        return this.userService.createUser(action.user).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        users: append([result]),
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new ResetUserFilter()))
        );
    }

    @Action(UpdateUser)
    public updateUser(ctx: StateContext<UsersStateModel>, action: UpdateUser): Observable<any> {
        return this.userService.updateUser(action.user).pipe(
            tap((result) => {
                ctx.setState(
                    patch({
                        users: updateItem<UserModel>((user) => user.id === action.user.id, result),
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new ResetUserFilter()))
        );
    }

    @Action(DeleteUser)
    public deleteUser(ctx: StateContext<UsersStateModel>, action: DeleteUser): Observable<any> {
        const state = ctx.getState();

        return this.userService.deleteUser(action.id).pipe(
            tap(() => {
                ctx.setState(
                    patch({
                        users: removeItem<UserModel>((user) => user.id === action.id),
                        user: iif<UserModel>(state.user?.id === action.id, null, state.user),
                    })
                );
            }),
            mergeMap(() => ctx.dispatch(new ResetUserFilter()))
        );
    }

    @Action(SetUserFilter)
    public setUserFilter(ctx: StateContext<UsersStateModel>, action: SetUserFilter): any {
        const state = ctx.getState();

        if (!action.filter) {
            return ctx.dispatch(new ResetUserFilter());
        }

        const filteredUsers = state.users.filter((user) => {
            const fieldsToCheck = [user.firstname.toLowerCase(), user.lastname.toLowerCase(), user.email.toLowerCase()];

            const isRelevant = fieldsToCheck.some((element) => element.indexOf(action.filter) !== -1);

            return isRelevant;
        });

        ctx.patchState({
            filteredUsers,
        });
    }

    @Action(ResetUserFilter)
    public resetUserFilter(ctx: StateContext<UsersStateModel>): void {
        const state = ctx.getState();

        ctx.patchState({
            filteredUsers: state.users,
        });
    }
}
