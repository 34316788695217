import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import { SubUrlEnum } from 'src/shared/enums/sub-urls-enum';

import { environment } from '../../environments/environment';
import { mockActivitiesRes } from '../mocks/activities.mock';
import { mockAnalysisRes } from '../mocks/analysis.mock';
import { mockJobsRes } from './../mocks/jobs.mock-response';
import { mockOffersRes } from './../mocks/offers.mock-response';
import { mockProgressRes } from './../mocks/progress.mock-response';
import { mockSitesRes } from './../mocks/sites.mock-response';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const mockEnv = environment.mock;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(
                mergeMap(() => {
                    /* PROGRESS */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.progress)) &&
                        request.url.endsWith(SubUrlEnum.SITE_PROGRESS)
                    ) {
                        return mockProgressRes(request);
                    }

                    /* OFFERS */
                    if ((mockEnv.all || (mockEnv.services && mockEnv.services.offers)) && request.url.includes(SubUrlEnum.OFFERS)) {
                        return mockOffersRes(request);
                    }

                    /* SITES */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.sites)) &&
                        (request.url.endsWith(SubUrlEnum.SITES_LIST) ||
                            request.url.endsWith(SubUrlEnum.SITE_ADD) ||
                            request.url.match(new RegExp(SubUrlEnum.SITE_DELETE + '[0-9]+')) ||
                            request.url.match(new RegExp(SubUrlEnum.SITE_UPDATE + '[0-9]+')))
                    ) {
                        return mockSitesRes(request);
                    }

                    /* ACTIVITIES */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.activities)) &&
                        (request.url.includes(SubUrlEnum.ACTIVITY_LIST) ||
                            request.url.match(new RegExp(SubUrlEnum.ACTIVITY_GET + '[0-9]+')))
                    ) {
                        return mockActivitiesRes(request);
                    }

                    /* JOBS */
                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.jobs)) &&
                        (request.url.includes(SubUrlEnum.JOB_ADD) ||
                            request.url.includes(SubUrlEnum.JOB_GET) ||
                            request.url.includes(SubUrlEnum.JOB_DELETE) ||
                            request.url.includes(SubUrlEnum.JOB_UPDATE))
                    ) {
                        return mockJobsRes(request);
                    }

                    if (
                        (mockEnv.all || (mockEnv.services && mockEnv.services.analysis)) &&
                        request.url.includes(SubUrlEnum.ANALYSE_ADD) &&
                        !request.url.includes(SubUrlEnum.ANALYSES_FREQUENCIES) &&
                        !request.url.includes(SubUrlEnum.ANALYSES_GRAVITIES) &&
                        !request.url.includes(SubUrlEnum.ANALYSES_PROBALITIES) &&
                        !request.url.includes(SubUrlEnum.ANALYSES_RISK_MANAGEMENT)
                    ) {
                        return mockAnalysisRes(request);
                    }

                    // pass through any requests not handled above
                    return next.handle(request);
                })
            )
            .pipe(
                // call materialize and dematerialize to ensure delay even if an error is thrown
                // (cfr: https://github.com/Reactive-Extensions/RxJS/issues/648)
                materialize(),
                delay(200),
                dematerialize()
            );
    }
}

// inspired by by this solution : http://jasonwatmore.com/post/2018/06/22/angular-6-mock-backend-example-for-backendless-development

export const mockInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: MockInterceptor,
    multi: true,
};
