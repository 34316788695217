<alert class="alert__container" type="danger" (click)=" onClick()" [ngClass]="(errors$ | async).length ? 'active' : ''">
    <div class="alert__header">
        <h4><strong>Oups!</strong> Une erreur est survenue</h4>
        <button class="btn alert__close" aria-label="close alert">
            <span class="icon icon-cross"></span>
        </button>
    </div>
    <div *ngFor="let error of errors$ | async">
        <p>{{ error }}</p>
    </div>
</alert>
