import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SubUrlEnum } from '../../../shared/enums/sub-urls-enum';
import { IAnalyse, IAnalyseOut, IAnalysisIn } from '../interfaces/pss.interface';

@Injectable({
    providedIn: 'root',
})
export class AnalyseService {
    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    createAnalyse(analyse: IAnalyse): Observable<IAnalysisIn> {
        return this.http
            .post<IAnalysisIn>(
                environment.API_URL + SubUrlEnum.ANALYSE_ADD,
                analyse
            )
            .pipe(
                map((data: IAnalysisIn) => {
                    return data;
                })
            );
    }

    updateAnalyse(analyse: IAnalyseOut, id: number): Observable<IAnalysisIn> {
        return this.http
            .put<IAnalysisIn>(
                environment.API_URL + SubUrlEnum.ANALYSE_ADD + '/' + id,
                analyse
            )
            .pipe(
                map((data: IAnalysisIn) => {
                    return data;
                })
            );
    }

    getAnalysisBySiteId(siteId: number): Observable<IAnalysisIn[]> {
        return this.http
            .get<any>(
                environment.API_URL +
                SubUrlEnum.ANALYSE_ADD +
                '?site_id=' +
                siteId
            )
            .pipe(
                map((data: any) => {
                    return data.analyses;
                })
            );
    }
}
