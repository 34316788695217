import { AlertTypesEnum } from './../../enums/alert-types.enum';

export class SetInfoMsg {
    static readonly type = '[Alert] Set Info Msg';

    constructor(public msg: string, public type: string = AlertTypesEnum.SUCCESS) { }
}


export class ResetInfoMsg {
    static readonly type = '[Alert] Reset Info Msg';
}
