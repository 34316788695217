import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { SiteType } from 'src/views/sites/models/site-type.model';
import { SiteService } from 'src/views/sites/services/sites.service';

import { SiteCategory } from '../../../views/sites/models/site-category.model';
import { GetAllSiteCategories, GetAllSiteTypes } from '../actions/site.action';

export class CategoryStateModel {
    categories: SiteCategory[];
    types: SiteType[];
    isListFetched: {
        cat: boolean,
        type: boolean
    };
}

@State<CategoryStateModel>({
    name: 'category',
    defaults: {
        categories: [],
        types: [],
        isListFetched: {
            cat: false,
            type: false
        }
    }
})

@Injectable()
export class SiteCategoryState {
    constructor(private siteService: SiteService) { }

    @Selector()
    static getAllSiteCategories(state: CategoryStateModel) {
        return state.categories;
    }

    @Selector()
    static getAllSiteTypes(state: CategoryStateModel) {
        return state.types;
    }

    @Action(GetAllSiteCategories)
    getAllSiteCategories(ctx: StateContext<CategoryStateModel>) {
        const state = ctx.getState();

        if (state.isListFetched.cat) {
            return;
        }

        return this.siteService.getCategories().pipe(tap((result) => {
            ctx.patchState({
                categories: result,
                isListFetched: {
                    ...state.isListFetched,
                    cat: true,
                }
            });
        }));
    }

    @Action(GetAllSiteTypes)
    getAllSiteTypes(ctx: StateContext<CategoryStateModel>) {
        const state = ctx.getState();
        if (state.isListFetched.type) {
            return;
        }

        return this.siteService.getTypes().pipe(tap((result) => {
            ctx.patchState({
                types: result,
                isListFetched: {
                    ...state.isListFetched,
                    type: true
                }
            });
        }));
    }
}
