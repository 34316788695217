import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { FormValidatorComponent } from './form-validator.abstract';


@Directive()
export abstract class FormControlComponent extends FormValidatorComponent implements ControlValueAccessor {

    public value;

    public touched = false;

    public disabled = false;

    public onChange = (value: any) => { };

    public onTouched = () => { };

    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(disabled: boolean): void {
        this.disabled = disabled;
    }

}
