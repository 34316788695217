import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubUrlEnum } from 'src/shared/enums/sub-urls-enum';
import { appendToFormData } from 'src/shared/utils/append-to-form-data';

import { IOffer, IOfferDto, IOffers } from '../interfaces/offer.interface';


@Injectable({
    providedIn: 'root'
})
export class SiteOfferService {

    constructor(private http: HttpClient) { }

    getOffers(siteId: number): Observable<IOffer[]> {
        const params = new HttpParams().set('site_id', siteId.toString());

        return this.http.get<IOffers>(environment.API_URL + SubUrlEnum.OFFERS, { params }).pipe(
            map(result => result.offers.map(offer => this.fromDto(offer)))
        );
    }

    createOffer(offer: IOffer): Observable<IOffer> {
        const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
        const serializedOffer = this.toFormData(this.toDto(offer));

        return this.http.post<IOfferDto>(environment.API_URL + SubUrlEnum.OFFERS, serializedOffer, { headers }).pipe(
            map(result => this.fromDto(result))
        );
    }

    updateOffer(offer: IOffer): Observable<IOffer> {
        const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
        const serializedOffer = this.toFormData(this.toDto(offer));

        return this.http.put<IOfferDto>(environment.API_URL + SubUrlEnum.OFFERS + offer.id, serializedOffer, { headers }).pipe(
            map(result => this.fromDto(result))
        );
    }

    deleteOffer(id: number): Observable<void> {
        return this.http.delete<void>(environment.API_URL + SubUrlEnum.OFFERS + id);
    }

    private toFormData(offer: IOfferDto): FormData {
        const fd = new FormData();

        for (const key of Object.keys(offer)) {
            if (offer[key] === null) {
                continue;
            }

            appendToFormData(`offer[${key}]`, offer[key], fd);
        }

        return fd;
    }

    private fromDto(backendObj: IOfferDto): IOffer {
        const offer: IOffer = {
            id: backendObj.id ? backendObj.id : null,
            siteId: backendObj.site_id ? backendObj.site_id : null,
            title: backendObj.title ? backendObj.title : null,
            fileUrl: backendObj.file_url ? backendObj.file_url : null,
            evaluation: backendObj.evaluation ? backendObj.evaluation : null
        };

        return offer;
    }

    private toDto(offer: IOffer): IOfferDto {
        const backendObj: IOfferDto = {
            id: offer.id ? offer.id : null,
            site_id: offer.siteId ? offer.siteId : null,
            title: offer.title ? offer.title : null,
            file: offer.file ? offer.file : null,
            evaluation: offer.evaluation ? offer.evaluation : null
        };

        return backendObj;
    }
}
