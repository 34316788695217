import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/shared/abstracts/base-component.abstract';

import { ProgressService } from './../../../views/sites/services/progress.service';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent extends BaseComponent implements OnInit {
    @Input() level: number;

    public readonly max: number = 120;
    public readonly minValue: number = 20;

    public progressBarTitle: string;
    public progress: number;

    public titles: string[] = [
        'Informations complétées',
        'Offre téléchargée',
        'Convention générée',
        'Rapport de visite généré',
        'Analyse créée',
        'DIU généré',
    ];

    constructor(private progressService: ProgressService) {
        super();
    }

    ngOnInit() {
        this.progressBarTitle = this.titles[this.level];
        this.progress = this.minValue * (this.level + 1);
    }
}
