import { IUser } from '../DTO/user.interface';

export class UserModel {
    public id: number;
    public firstname: string;
    public lastname: string;
    public email: string;
    public password: string;

    constructor(base: Partial<UserModel>) {
        Object.assign(this, base);
    }


    public static fromDto(dto: IUser): UserModel {
        return new UserModel({
            id: dto && dto && dto.id ? dto.id : null,
            firstname: dto && dto.firstname ? dto.firstname : null,
            lastname: dto && dto.lastname ? dto.lastname : null,
            email: dto && dto.email ? dto.email : null,
            password: dto && dto.password ? dto.password : null,
        });
    }
}
