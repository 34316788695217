import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthState } from '../store/state/auth.state';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(
        private store: Store
    ) { }

    private headerConfig: any = {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-API-KEY': environment.API_KEY
    };

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let headers = Object.keys(this.headerConfig).reduce(
            (currentHeader, k) => currentHeader.set(k, this.headerConfig[k]),
            new HttpHeaders()
        );
        req.headers.keys().forEach((key) => {
            headers = headers.set(key, req.headers.get(key));
        });

        const token = this.store.selectSnapshot(AuthState.getAccessToken);

        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        if (req.headers.get('Content-Type') === 'multipart/form-data') {
            headers = headers.delete('Content-Type');
        }

        const authReq = req.clone({ headers });
        return next.handle(authReq);
    }
}
